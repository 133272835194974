import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { paths } from "./paths";
import ProtectedRoutes from "./protected-routes";
import PublicRoutes from "./public-routes";

// pages
import ErrorPage from "../pages/error-page";
import Home from "../pages/app/home";
import Signin from "../pages/auth/signin";
import Signup from "../pages/auth/signup";
import Host from "../pages/auth/host";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import FAQ from "../pages/app/home/faq";
import HostOnboard from "../pages/app/host-onboard";
import HostDashboard from "../pages/app/host-dashboard";
import ManageProperty from "../pages/app/host-dashboard/manage-property";
import ViewProperty from "../pages/app/view-property";
import SearchList from "../pages/app/search-list";
import HostType from "../pages/app/host-type";
import Loader from "../pages/loader-page";
import EmailVerify from "../pages/auth/verify-email";
import { useDispatch } from "react-redux";
import { fetchUser } from "../redux/actions/authuser";
import EnterAddress from "../pages/app/host-onboard/components/EnterAddress";
import ConfirmAddress from "../pages/app/host-onboard/components/ConfirmAddress";
import PropertyType from "../pages/app/host-onboard/components/PropertyType";
import MoreDetailsProperty from "../pages/app/host-onboard/components/MoreDetailsProperty";
import PropertyAmenity from "../pages/app/host-onboard/components/PropertyAmenity";
import UplaodImage from "../pages/app/host-onboard/components/UploadImages";
import AddShortTitle from "../pages/app/host-onboard/components/AddShortTitle";
import AddMoreDetails from "../pages/app/host-onboard/components/AddMoreDetails";
import ChooseContact from "../pages/app/host-onboard/components/ChooseContact";
import SetPriceProperty from "../pages/app/host-onboard/components/SetPriceProperty";
import SetHouseRules from "../pages/app/host-onboard/components/SetHouseRules";
import ReviewListing from "../pages/app/host-onboard/components/ReviewListing";
import { allCategories } from "../redux/actions/property";
import Profile from "../pages/app/profile";
import Settings from "../pages/app/profile/components/Settings";
import Legal from "../pages/app/profile/components/Legal";
import Auth from "../middleware/storage";
import LatestActivity from "../pages/app/host-dashboard/latest-activity";
import PublishedListing from "../pages/app/host-dashboard/published-listing";
import PendingReview from "../pages/app/host-dashboard/pending-review";
import Draft from "../pages/app/host-dashboard/draft";
import Verification from "../pages/app/profile/components/Verification";
import PrivacyPolicy from "../pages/app/home/privacy-policy";
import ScrollTop from "../components/custom/scroll-to-top";
import Booking from "../pages/app/booking";
import ProfileV2 from "../pages/app/profile-v2.0";
import MyBookings from "../pages/app/profile-v2.0/components/MyBookings";
import ReservationDetails from "../pages/app/profile-v2.0/components/ReservationDetails";

function AppRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      // @ts-ignore
      dispatch(fetchUser());
    }
    // @ts-ignore
    dispatch(allCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BrowserRouter>
        <ScrollTop />
        <Helmet>
          <title>EAD Shortlets</title>
          <meta name="description" content="Your online shortlets" />
          <meta name="keywords" content="Properties, Apartments" />
        </Helmet>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={paths.HOME} element={<Home />} />
            <Route path={paths.FAQ} element={<FAQ />} />

            <Route path={paths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route
              path={paths.VIEW_PROPERTY + "/:id"}
              element={<ViewProperty />}
            />
            <Route path={paths.SEARCH_LIST} element={<SearchList />} />
            <Route path={paths.EMAIL_VERIFY} element={<EmailVerify />} />

            <Route element={<ProtectedRoutes />}>
              <Route path={paths.HOST_LANDING} element={<HostOnboard />}>
                <Route path="enter-address" element={<EnterAddress />} />
                <Route path="confirm-address" element={<ConfirmAddress />} />
                <Route path="property-type" element={<PropertyType />} />
                <Route
                  path="property-details"
                  element={<MoreDetailsProperty />}
                />
                <Route path="property-amenity" element={<PropertyAmenity />} />
                <Route path="property-image-upload" element={<UplaodImage />} />
                <Route path="property-title" element={<AddShortTitle />} />
                <Route
                  path="property-description"
                  element={<AddMoreDetails />}
                />
                <Route path="property-contact" element={<ChooseContact />} />
                <Route path="property-price" element={<SetPriceProperty />} />
                <Route path="property-rules" element={<SetHouseRules />} />
                <Route path="property-review" element={<ReviewListing />} />
              </Route>
              <Route path={paths.BOOKING} element={<Booking />} />
              <Route path={paths.PROFILE_V2} element={<ProfileV2 />}>
                <Route path="settings" element={<Settings />} />
                <Route path="legal" element={<Legal />} />
                <Route path="bookings" element={<MyBookings />} />
                <Route
                  path="reservationDetails"
                  element={<ReservationDetails />}
                />
                <Route path="verification" element={<Verification />} />
              </Route>
              <Route path={paths.HOST_TYPE} element={<HostType />} />
              <Route path={paths.HOST_DASHBOARD} element={<HostDashboard />}>
                <Route path="latest-activity" element={<LatestActivity />} />
                <Route
                  path="published-listing"
                  element={<PublishedListing />}
                />
                <Route path="pending-review" element={<PendingReview />} />
                <Route path="draft" element={<Draft />} />
              </Route>
              <Route
                path={`${paths.HOST_DASHBOARD_MANAGE_PROPERTY}/:id`}
                element={<ManageProperty />}
              />
              <Route path={paths.PROFILE} element={<Profile />}>
                <Route path="settings" element={<Settings />} />
                <Route path="legal" element={<Legal />} />
                <Route path="verification" element={<Verification />} />
              </Route>
            </Route>

            <Route element={<PublicRoutes />}>
              <Route path={paths.SIGNIN} element={<Signin />} />
              <Route path={paths.SIGNUP} element={<Signup />} />
              <Route path={paths.HOST} element={<Host />} />
              <Route
                path={paths.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route path={paths.RESET_PASSWORD} element={<ResetPassword />} />
            </Route>

            <Route path={paths.ERROR} element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default AppRoutes;
