import axios from "axios";
import { toast } from "react-toastify";

export const autocompletePlaces = async (input: string) => {
  let requestUrl =
    process.env.REACT_APP_GOOGLEAPIS +
    "&key=" +
    process.env.REACT_APP_APIKEY +
    "&input=" +
    input;
  try {
    const result = await axios({
      method: "get",
      url: requestUrl,
      timeout: 60 * 2 * 1000,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getGooglePlaceInfo = async (placeId: string) => {
  let requestUrl = `${process.env.REACT_APP_GOOGLE_GEO_API_PLACES}?placeid=${placeId}&key=${process.env.REACT_APP_APIKEY}`;
  try {
    const result = await axios({
      method: "get",
      url: requestUrl,
      timeout: 60 * 2 * 1000,
    });
    return result.data.result;
  } catch (error) {
    console.log(error);
  }
};

export const showToast = (msg: string, type: string) => {
  switch (type) {
    case "error":
      return toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    case "success":
      return toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    default:
      return toast(msg, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
  }
};

export const truncate = (s: string, n: number) => {
  return s.length > n ? s.substring(0, n - 1) + "..." : s;
};

export const currencyFormat = (
  price: string | number,
  currency?: string
): string =>
  `${currency || "₦"}${
    typeof price === "string"
      ? Number(price || 0).toLocaleString()
      : price.toLocaleString()
  }`;

export const validateEmail = (email: string) => {
  // Define the email regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Test the email against the regex
  return emailRegex.test(email);
};

export const findInputError = (errors: { [x: string]: any }, name: string) => {
  const filtered = Object.keys(errors)
    .filter((key) => key.includes(name))
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {});
  return filtered;
};

export const isFormInvalid = (err: {}) => {
  if (Object.keys(err).length > 0) return true;
  return false;
};
