import React from "react";
import ArrowTopRight from "../icons/arrow-top-right";
import Location from "../icons/location";
import "./custom.css";
import { Link } from "react-router-dom";
import { currencyFormat, truncate } from "../../utils/helper-functions";
import { PropertyType } from "../../redux/types";
import PillsTwo from "./pills-two";
import Carousel from "./carousel";
import { getIconComponent } from "../../utils/get-icons";

interface Prop {
  price: number | string;
  duration: string;
  title: string;
  img?: string;
  desc: string;
  location: string;
  marginTop?: number;
  to: string;
  item?: PropertyType;
}

const ShortletsCard = ({
  price,
  duration,
  img,
  title,
  desc,
  location,
  marginTop,
  to,
  item,
}: Prop) => {
  return (
    <div
      className={`shortletsCard${marginTop} flex flex-col justify-between lg:w-60 lg:max-w-60 w-80 my-4 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
    >
      <Carousel
        // @ts-ignore
        slides={item?.property_media}
        classNameImg="rounded-xl shadow-lg object-cover w-80 h-80"
      />
      <Link className="flex flex-col" to={to}>
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-2">
            <p className="text-lg font-bold text-gray1000 dark:text-gray300 font-display">
              {currencyFormat(price || 0)}
            </p>
            <span className="text-xs font-display text-gray1000 dark:text-gray300">
              {duration}
            </span>
          </div>
          <ArrowTopRight color="#525252" className="w-2.5" />
        </div>
        <p className="text-sm font-bold font-display text-gray900 my-2 dark:text-gray300">
          {title}
        </p>
        <p className="text-xs font-display text-gray800 dark:text-gray300">
          {truncate(desc, 70)}
        </p>
        <div className="flex flex-wrap items-center gap-1 overflow-x-scroll no-scrollbar my-3">
          <PillsTwo
            title={`${item?.bedrooms ?? 0} Bedroom${
              (item?.bedrooms ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("room-icon")}
          />
          <PillsTwo
            title={`${item?.bathrooms ?? 0} Bathroom${
              (item?.bathrooms ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("bathroom-icon")}
          />
          <PillsTwo
            title={`${item?.toilets ?? 0} Toilet${
              (item?.toilets ?? 0) > 1 ? "s" : ""
            }`}
            icon={getIconComponent("toilet-icon")}
          />

          {/* Add additional PillsTwo components as needed, based on item data */}
        </div>
        <div className="flex gap-2 items-center">
          <Location />
          <p className="text-xs font-display text-gray800 dark:text-gray300">
            {truncate(location, 30)}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ShortletsCard;
