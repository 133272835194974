import React from "react";
import CheckMarkIcon from "../../../../components/icons/check-mark";

interface Task {
  name: string;
  isCompleted: boolean;
}

interface VerificationProps {
  title: string;
  isUploaded: boolean;
  isUnverified: boolean;
  tasks: Task[];
}

const Verification: React.FC<VerificationProps> = ({
  title,
  isUploaded,
  isUnverified,
  tasks,
}) => {
  return (
    <div className="text-gray1000 dark:bg-transparent dark:text-gray100 dark:border-gray800 bg-gray100 border border-gray400 rounded-xl p-4 flex flex-col gap-3 mt-4">
      <div className="flex flex-col md:flex-row gap-3">
        <span className="font-medium md:text-base text-sm">{title}</span>
        <div className="flex gap-1">
          {" "}
          {isUploaded && (
            <div className="bg-gray400 dark:bg-[#660808] h-fit dark:text-primary100 text-primary500 w-fit rounded-full py-[0.1rem] font-medium px-3 flex items-center justify-center text-xs md:text-sm">
              uploaded
            </div>
          )}
          {isUnverified && (
            <div className="bg-gray400 dark:bg-gray900 h-fit w-fit rounded-full py-[0.1rem] font-medium px-3 flex items-center justify-center text-xs md:text-sm">
              unverified
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 text-gray900">
        {tasks.map((task, index) => (
          <div key={index} className="flex items-start gap-3">
            <div
              className={`mt-1.5 text-[0.4rem] md:text-[0.6rem] ${
                task.isCompleted
                  ? "text-gray1000 dark:text-gray100"
                  : "text-primary400"
              }`}
            >
              ⬤
            </div>
            <div className="flex items-center gap-3 md:text-base text-sm">
              <span
                className={` ${
                  task.isCompleted
                    ? "text-gray700 dark:text-gray600"
                    : "text-gray900 dark:text-gray100"
                }`}
              >
                {task.name}
              </span>
              {task.isCompleted && <CheckMarkIcon />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Verification;
