import React from "react";
import Bedroom from "../icons/bedroom";
import Pills from "./pills";
import Bathroom from "../icons/bathroom";
import Wifi from "../icons/wifi";
import Location from "../icons/location";
import Avatar from "../icons/avatar";
import Emailicon from "../icons/email";
import Whatsapp from "../icons/whatsapp";
import Phoneicon from "../icons/phone";
import HeartEmpty from "../icons/heart-empty";
import Award from "../icons/award";
import { Link } from "react-router-dom";

type Props = {
  price: string;
  icon?: any;
  pic?: string;
  title?: string;
  description?: string;
  location?: string;
  hostname?: string;
  to?: string;
};

const SimilarListing = ({
  title,
  icon,
  pic,
  price,
  description,
  location,
  hostname,
  to
}: Props) => {
  return (
    <div className="lg:flex my-4 gap-2 items-center relative w-full flex-shrink-0">
      <img src={pic} className="h-52 lg:w-1/2 w-full rounded-lg" alt="" />
      <Link to={to || '#'} target="_blank" className="flex flex-col gap-1 w-full">
        <div className="lg:flex items-center justify-between hidden">
          <div className="flex gap-1 rounded-full bg-primary100 items-center p-1">
            <Award />
            <span className="text-xs font-display text-primary500">
              Rare find
            </span>
          </div>
          <HeartEmpty className="h-8" />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <p className="text-xl font-display font-bold dark:text-gray200">{price}</p>
          <p className="text-sm font-display text-gray900 dark:text-gray200">per night</p>
        </div>
        <p className="font-display text-base font-bold text-gray900 dark:text-gray200">{title}</p>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray800 font-display dark:text-gray200">{description}</p>
          <div className="flex items-center gap-2 overflow-x-scroll no-scrollbar my-1">
            <Pills
              title="1Bedroom"
              icon={icon || <Bedroom className="w-3" />}
            />
            <Pills
              title="1Bathroom"
              icon={icon || <Bathroom className="w-3" />}
            />
            <Pills title="1Wifi" icon={icon || <Wifi className="w-3" />} />
          </div>
          <div className="flex gap-2 items-center">
            <Location />
            <p className="text-xs font-display text-gray800 dark:text-gray200">{location}</p>
          </div>
          <div className="lg:flex items-center justify-between hidden">
            <div className="flex items-center gap-2 mt-1">
              <Avatar className="h-6" />
              <p className="text-sm font-display text-gray-800 dark:text-gray200">
                Hosted by {hostname}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="flex ">
                <Emailicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">Email</span>
              </div>
              <div className="flex ">
                <Whatsapp className="h-4" />
                <span className="text-xs font-display dark:text-gray200">Whatsapp</span>
              </div>
              <div className="flex ">
                <Phoneicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">Call</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SimilarListing;
