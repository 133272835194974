import React, { useState, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../utils/helper-functions";
import PhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  type?: string;
  subtext?: string;
  value?: string | number;
  className?: string;
  required?: any;
  errorMsg?: string;
  readOnly?: boolean;
}
interface TextAreaProps {
  label?: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  type?: string;
  subtext?: string;
  value?: string;
  className?: string;
}

interface InputProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  subtext?: string;
  className?: string;
  readOnly?: boolean;
  value?: string;
  validation?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

interface InputTextAreaProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  subtext?: string;
  className?: string;
  value?: string;
  validation?: any;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

interface PhoneInputFieldProps {
  label?: string;
  className?: string;
  value?: string;
  errMsg?: string | null;
  country?: string | number | undefined;
  onChange?: (
    value: string,
    data: CountryData | {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}

export const TextInput = forwardRef(
  (
    {
      label,
      placeholder,
      onChange,
      type,
      subtext,
      className,
      required,
      errorMsg,
      readOnly,
      ...rest
    }: Props &
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
    ref
  ) => (
    <div className={`flex flex-col gap-1 w-full lg:w-1/2  ${className}`}>
      <label className="font-display text-sm dark:text-gray200">{label}</label>
      <input
        name={label?.trim()}
        type={type || "text"}
        placeholder={placeholder}
        className="p-1.5 px-3 rounded-full border placeholder:text-sm text-sm border-gray500 dark:bg-gray500 font-display focus:border-primary400 transition-all duration-300 ease-in-out outline-none focus:ring-0 focus:outline-none"
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        {...rest}
      />
      <p className="font-display text-xs dark:text-gray200">{subtext}</p>
      {errorMsg && (
        <p className="font-display flex items-center gap-2 text-xs text-primary500 bg-primary100 p-1 px-2 rounded-full">
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00001 4.66602V7.33268M8.00001 9.99935H8.00668M14.6667 7.33268C14.6667 11.0146 11.6819 13.9993 8.00001 13.9993C4.31811 13.9993 1.33334 11.0146 1.33334 7.33268C1.33334 3.65078 4.31811 0.666016 8.00001 0.666016C11.6819 0.666016 14.6667 3.65078 14.6667 7.33268Z"
              stroke="#FF3333"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {errorMsg}
        </p>
      )}
    </div>
  )
);

export const TextAreaInput = forwardRef(
  (
    {
      label,
      placeholder,
      onChange,
      type,
      subtext,
      value,
      className,
      ...rest
    }: TextAreaProps,
    ref
  ) => (
    <div className="flex flex-col gap-1 w-full">
      <label className="font-display text-sm dark:text-gray200">{label}</label>
      <textarea
        name=""
        id=""
        placeholder={placeholder}
        className={`p-3 rounded-lg border border-gray500 dark:border-gray800 font-display focus:border-primary400 transition-all duration-300 ease-in-out outline-none focus:ring-0 focus:outline-none ${className}`}
        onChange={onChange}
        {...rest}
        value={value}
        rows={5}
      />
      <p className="font-display text-sm">{subtext}</p>
    </div>
  )
);

export const InputField = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      type,
      subtext,
      className,
      readOnly,
      validation,
      value,
      onChange,
      icon,
      ...rest
    }: InputProps & { icon?: React.ReactNode },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const inputErrors = findInputError(errors, name) as any;
    const isInvalid = isFormInvalid(inputErrors);

    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className={`flex flex-col gap-1 w-full lg:w-1/2 ${className}`}>
        <label className="font-display text-sm dark:text-gray200">
          {label}
        </label>
        <div className="relative">
          {icon && (
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray600">
              {icon}
            </span>
          )}
          <input
            id={name}
            type={type === "password" && !showPassword ? "password" : "text"}
            placeholder={placeholder}
            value={value}
            className={`p-1.5 rounded-full border dark:text-gray200 placeholder:text-sm text-sm border-gray500 dark:border-gray800 dark:bg-transparent font-display focus:border-primary400 transition-all duration-300 ease-in-out outline-none focus:ring-0 focus:outline-none w-full ${
              icon ? "pl-14 pr-3" : "px-3"
            }`}
            {...rest}
            {...register(name, validation)}
            onChange={onChange}
          />

          {type === "password" && (
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible className="text-gray600 text-lg" />
              ) : (
                <AiOutlineEye className="text-gray600 text-lg" />
              )}
            </span>
          )}
        </div>
        <p className="font-display text-xs dark:text-gray400 text-gray600">{subtext}</p>
        {isInvalid && (
          <p className="font-display flex items-center gap-2 text-xs text-primary500 bg-primary100 p-1 px-2 rounded-full">
            <svg
              width="16"
              height="15"
              className="flex-shrink-0"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 4.66602V7.33268M8.00001 9.99935H8.00668M14.6667 7.33268C14.6667 11.0146 11.6819 13.9993 8.00001 13.9993C4.31811 13.9993 1.33334 11.0146 1.33334 7.33268C1.33334 3.65078 4.31811 0.666016 8.00001 0.666016C11.6819 0.666016 14.6667 3.65078 14.6667 7.33268Z"
                stroke="#FF3333"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {inputErrors?.error?.message}
          </p>
        )}
      </div>
    );
  }
);

export default InputField;

export const InputTextAreaField = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      type,
      subtext,
      className,
      validation,
      value,
      onChange,
      ...rest
    }: InputTextAreaProps,
    ref
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const inputErrors = findInputError(errors, name) as any;
    const isInvalid = isFormInvalid(inputErrors);

    return (
      <div className="flex flex-col gap-1 w-full">
        <label className="font-display text-sm dark:text-gray200">
          {label}
        </label>
        <textarea
          id={name}
          placeholder={placeholder}
          className={`p-3 rounded-lg border border-gray500 font-display focus:border-primary400 transition-all duration-300 ease-in-out outline-none focus:ring-0 focus:outline-none ${className}`}
          // onChange={onChange}
          {...rest}
          value={value}
          rows={5}
          {...register(name, validation)}
        />
        <p className="font-display text-xs dark:text-gray200">{subtext}</p>
        {isInvalid && (
          <p className="font-display flex items-center gap-2 text-xs text-primary500 bg-primary100 p-1 px-2 rounded-full">
            <svg
              width="16"
              height="15"
              className="flex-shrink-0"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 4.66602V7.33268M8.00001 9.99935H8.00668M14.6667 7.33268C14.6667 11.0146 11.6819 13.9993 8.00001 13.9993C4.31811 13.9993 1.33334 11.0146 1.33334 7.33268C1.33334 3.65078 4.31811 0.666016 8.00001 0.666016C11.6819 0.666016 14.6667 3.65078 14.6667 7.33268Z"
                stroke="#FF3333"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {inputErrors.error.message}
          </p>
        )}
      </div>
    );
  }
);

export const PhoneInputField = forwardRef(
  (
    {
      country,
      errMsg,
      label,
      className,
      value,
      onChange,
      ...rest
    }: PhoneInputProps & PhoneInputFieldProps,
    ref
  ) => {
    return (
      <div className={`flex flex-col gap-1 w-full lg:w-1/2 ${className}`}>
        <label className="font-display text-sm dark:text-gray200">
          {label}
        </label>
        <PhoneInput
          country={country || "ng"}
          value={value}
          onChange={onChange}
          {...rest}
        />
        {errMsg && (
          <p className="font-display flex items-center gap-2 text-xs text-primary500 bg-primary100 p-1 px-2 rounded-full">
            <svg
              width="16"
              height="15"
              className="flex-shrink-0"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 4.66602V7.33268M8.00001 9.99935H8.00668M14.6667 7.33268C14.6667 11.0146 11.6819 13.9993 8.00001 13.9993C4.31811 13.9993 1.33334 11.0146 1.33334 7.33268C1.33334 3.65078 4.31811 0.666016 8.00001 0.666016C11.6819 0.666016 14.6667 3.65078 14.6667 7.33268Z"
                stroke="#FF3333"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {errMsg || "Phone number is required"}
          </p>
        )}
      </div>
    );
  }
);
