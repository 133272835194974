import React from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";

const Legal = () => {
  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo name="Legal" path={`${paths.PROFILE}/legal`} />
      </div>
    </div>
  );
};

export default Legal;
