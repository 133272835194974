import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";
import { getVerificationChecklist } from "../../../../redux/reducers/property-slice";
import moment from "moment";
import { paths } from "../../../../routes/paths";
import { useNavigate } from "react-router-dom";
import {
  updateProperty,
  uploadDocsSingle,
  viewChecklist,
} from "../../../../redux/actions/property";
import Modal from "../../../../components/custom/modal";
import BookInspection from "../../host-onboard/components/BookInspection";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { CategoryProp } from "../../../../redux/types";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import {
  fetchUser,
  updateUserDocument,
} from "../../../../redux/actions/authuser";
import UploadFiles from "../../../../components/uploadFiles";
import { TextInput } from "../../../../components/custom/text-input";

const VerifyCheckListItem = ({
  title,
  desc,
  btnTitle,
  action,
  status,
}: {
  title: string;
  desc: string;
  btnTitle: string;
  action: () => void;
  status: string;
}) => (
  <div className="p-2 border border-gray400 dark:border-gray800 rounded-xl my-2">
    <div className="flex gap-2 items-center pb-2">
      <p className="text-sm font-semibold text-gray1000 dark:text-gray200">
        {title}
      </p>
      <p className="text-xs bg-gray400 rounded-full px-[4px] py-[2px]">
        {status}
      </p>
    </div>
    <p className="text-sm font-light text-gray1000 dark:text-gray200 pb-2">
      {desc}
    </p>
    <button
      onClick={action}
      className="w-fit justify-center items-center rounded-full p-1.5 bg-gray400 dark:bg-gray800 my-2 px-2"
    >
      <span className="text-sm text-gray1000 dark:text-gray200 font-semibold">
        {btnTitle}
      </span>
    </button>
  </div>
);

type ValuePiece = Date | null;

const IncompleteVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getCurrentUser);
  const isLoading = useSelector(getIsLoading);
  const verificationStatus = useSelector(getVerificationChecklist);
  const [open, setOpen] = useState<boolean>(false);
  const [value, onChange] = useState<ValuePiece>(new Date());
  const [propertyId, setPropertyId] = useState(0);
  const [uploadKey, setUploadKey] = useState(false);
  const [docurl, setDocUrl] = useState<string | null>(null);
  const [errorMsg, setErroMsg] = useState("");
  const [docType, setDoctype] = useState<string>("");
  const [docNo, setDocNo] = useState<string>("");
  // const [picture, setPicture] = useState<string>("");
  const propertyCategories = JSON.parse(
    localStorage.getItem("allcategories") || "{}"
  ) as CategoryProp;

  const onBookInspection = async () => {
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      updateProperty({ id: propertyId, inspection_date: value?.toISOString() })
    );
    if (res) {
      setOpen(false);
      // @ts-ignore
      dispatch(viewChecklist());
    }
  };

  const handleDocUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/jpeg,image/gif,image/png,application/pdf";
    fileInput.click();
    const fd = new FormData();
    setDocUrl("");
    fileInput.onchange = async (event: any) => {
      fd.append("media", event.target.files[0], "media");
      // @ts-ignore
      const res = (await dispatch(uploadDocsSingle(fd))) as string;
      if (res) {
        setDocUrl(res);
        // setDocfile((prevUploadedFiles) => [...prevUploadedFiles, res]);
      }
      return;
    };
  };

  // const handleUploadClick = () => {
  //   const fileInput = document.createElement("input");
  //   fileInput.type = "file";
  //   // fileInput.accept = "image/jpeg,image/gif,image/png,application/pdf";
  //   fileInput.click();
  //   const fd = new FormData();
  //   setPicture("");
  //   fileInput.onchange = async (event: any) => {
  //     fd.append("media", event.target.files[0], "media");
  //     // @ts-ignore
  //     const res = (await dispatch(uploadImagesSingleNoWaterMark(fd))) as string;
  //     if (res) {
  //       setPicture(res);
  //       // setDocfile((prevUploadedFiles) => [...prevUploadedFiles, res]);
  //     }
  //     return;
  //   };
  // };

  const saveUpload = async () => {
    if (docType.length < 1) {
      setErroMsg("Kindly select a document type");
      return;
    }
    if (!docurl) {
      setErroMsg("Kindly select a document image or a profile picture");
      return;
    }
    const res = await dispatch(
      // @ts-ignore
      updateUserDocument({
        document_type: docType,
        document_image_url: docurl,
        document_number: docNo,
        user_selfie_url: null,
      })
    );

    if (res) {
      setUploadKey(false);
      // setOpen(false);
      // setImageUrl(null);
      // @ts-ignore
      dispatch(fetchUser());
      // @ts-ignore
      dispatch(viewChecklist());
    }
  };

  return (
    <div>
      {uploadKey ? (
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-xs text-primary500">{errorMsg}</p>
            <DropdownTwo
              label="Document type*"
              // @ts-ignore
              options={
                propertyCategories.userKycDocumentTypes.map((item) => {
                  return { value: item, label: item };
                }) || []
              }
              onSelect={(e) => {
                setDoctype(e);
                setErroMsg("");
              }}
              value={docType || "Select"}
            />
          </div>
          <TextInput
            label="Document no*"
            placeholder="Document no"
            onChange={(e: any) => setDocNo(e.target.value)}
            type="number"
            required
            value={docNo}
            className=""
          />
          <div className="flex items-center justify-between">
            <div className="w-full">
              {docurl ? (
                <div>
                  <p className="py-2 text-primary500 text-base">
                    document file uploaded
                  </p>
                </div>
              ) : (
                <UploadFiles
                  onClick={handleDocUploadClick}
                  loader={isLoading}
                />
              )}
            </div>
            {/* <div className="w-full h-40">
              {picture ? (
                <img src={picture} className="w-full object-cover h-40" alt="" />
              ) : (
                <Upload onClick={handleUploadClick} loader={isLoading} />
              )}
            </div> */}
          </div>

          <button
            onClick={saveUpload}
            disabled={!docType}
            className={`text-xs flex items-center gap-2 font-bold text-eadWhite place-self-center ${
              !docType || isLoading
                ? "bg-primary300"
                : "bg-primary500"
            } rounded-full p-2 w-fit place-self-end flex-shrink-0`}
          >
            {isLoading ? "uplaoding" : "upload"}
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {!verificationStatus.user.is_email_verified && (
            <VerifyCheckListItem
              title={"Email"}
              desc={`Please verify your email address by clicking the link in the
                   message we just sent to: ${profile?.email}`}
              btnTitle={"Resend verification email"}
              status="unverified"
              action={() => console.log("email")}
            />
          )}
          {!verificationStatus.user.is_bank_verified && (
            <VerifyCheckListItem
              title={"Bank Details"}
              desc={`Please add your bank account details from your profile settings in the payment method section`}
              btnTitle={"Add bank details"}
              status="unverified"
              action={() => navigate(`${paths.PROFILE}/settings?tab=Payment`)}
            />
          )}
          {(!verificationStatus.user.kyc_document_url || verificationStatus.user.verification_status !== 'verified') && (
            <VerifyCheckListItem
              title={"Document Verification"}
              desc={`Please upload your documents for ID verification. Eg: Passport, Driving License`}
              btnTitle={"Upload document"}
              status={verificationStatus.user.verification_status}
              action={() => setUploadKey(true)}
            />
          )}
          {verificationStatus?.user_properties.map((item, index) => {
            if (!item.is_inspected)
              return (
                <VerifyCheckListItem
                  title={"Property inspection"}
                  desc={`${item?.name} on ${moment(
                    item?.inspection_date
                  ).format("D MMMM YYYY")}`}
                  btnTitle={"Reschedule"}
                  status={item.verification_status}
                  action={() => {
                    setOpen(true);
                    setPropertyId(item.id);
                  }}
                />
              );

            return <></>;
          })}

          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <BookInspection
              onCancel={() => {
                setOpen(false);
              }}
              onConfirm={onBookInspection}
              onChange={onChange}
              value={value}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default IncompleteVerification;
