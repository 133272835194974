import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyForm,
  setPropertyForm,
} from "../../../../redux/reducers/property-slice";
import { PropertyFormType } from "../../../../redux/types";
import { useEffect } from "react";

type CategoryProp = {
  amenities: [{ icon_tag: string; id: number; name: string }];
  rules: [];
  subCategories: [
    { category_id: number; icon_url: string; id: number; name: string }
  ];
  userKycDocumentTypes: string[]
};

export const propertyDefaultForm = {
  name: "",
  description: "",
  price: "",
  address: "",
  longitude: 0,
  latitude: 0,
  country: "",
  google_location: {} || null,
  currency_id: 0,
  category_id: 1,
  sub_category_id: 0,
  media_urls: [],
  is_negotiable: false,
  status: "available",
  details: [],
  bedrooms: 0,
  bathrooms: 0,
  toilets: 0,
  guests: 0,
  hide_location: true,
  host_type: "",
  amenities: [],
  check_in_time: "14:00:00",
  check_out_time: "12:00:00",
  rules: [],
  contact_phone_number: "",
  contact_email: "",
  contact_whatsapp_number: "",
  inspection_date: null,
};

export const usePropertyForm = () => {
  const dispatch = useDispatch();
  const propertyFormValue = useSelector(getPropertyForm);
  const propertyCategories = JSON.parse(
    localStorage.getItem("allcategories") || "{}"
  ) as CategoryProp;

  const setPropertyFieldValue = (value: PropertyFormType) => {
    dispatch(setPropertyForm({ ...propertyFormValue, ...value }));
    localStorage.setItem(
      "property",
      JSON.stringify({ ...propertyFormValue, ...value })
    );
  };

  const resetPropertyDefault = () => {
    dispatch(setPropertyForm(propertyDefaultForm));
    localStorage.setItem("property", JSON.stringify(propertyDefaultForm));
  };


  useEffect(() => {
    const propertyformfields = JSON.parse(
      localStorage.getItem("property") || `{}`
    );
    dispatch(setPropertyForm({ ...propertyFormValue, ...propertyformfields }));
    // eslint-disable-next-line
  }, []);

  return {
    setPropertyFieldValue,
    propertyFormValue,
    propertyCategories,
    resetPropertyDefault,
  };
};
