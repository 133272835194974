import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/custom/navbar";
import Footer from "../../../components/custom/footer";
import { useEffect, useState } from "react";
import { paths } from "../../../routes/paths";
import { useDispatch } from "react-redux";
import { viewChecklist } from "../../../redux/actions/property";
import {
  fetchAllBanks,
  fetchUser,
  fetchUserProperties,
} from "../../../redux/actions/authuser";

const Profile_v2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [active, setActive] = useState(pathnames[1]);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchUser());
    // @ts-ignore
    dispatch(fetchUserProperties());
    // @ts-ignore
    dispatch(viewChecklist());

    // @ts-ignore
    dispatch(fetchAllBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActive(pathnames[1]);
  }, [pathnames]);

  return (
    <div className="bg-gray200 relative dark:bg-gray1000">
      <Navbar />
      <div className="max-w-screen-lg mx-auto lg:py-16">
        <div className="lg:flex items-start justify-center lg:gap-6 gap-4 lg:mx-0 mx-2">
          <div className="lg:w-1/3 border rounded-2xl border-gray-300 dark:bg-gray1000 dark:border-gray800 bg-gray300 p-3 h-fit">
            <p
              onClick={() => {
                setActive("settings");
                navigate(`${paths.PROFILE_V2}/settings`);
              }}
              className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out ${
                active === "settings" && "bg-gray400 dark:bg-gray800"
              } rounded-lg my-3 cursor-pointer`}
            >
              Settings
            </p>
            <p
              onClick={() => {
                setActive("verification");
                navigate(`${paths.PROFILE_V2}/verification`);
              }}
              className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out ${
                active === "verification" && "bg-gray400 dark:bg-gray800"
              } rounded-lg my-3 cursor-pointer`}
            >
              Verification
            </p>
            <p
              onClick={() => {
                setActive("legal");
                navigate(`${paths.PROFILE_V2}/legal`);
              }}
              className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out ${
                active === "legal" && "bg-gray400 dark:bg-gray800"
              } rounded-lg my-3 cursor-pointer`}
            >
              Legal
            </p>{" "}
            <p
              onClick={() => {
                setActive("bookings");
                navigate(`${paths.PROFILE_V2}/bookings`);
              }}
              className={`font-display text-sm font-bold p-2 dark:text-gray200 transition-all duration-200 ease-in-out ${
                (active === "bookings" || active === "reservationDetails") &&
                "bg-gray400 dark:bg-gray800"
              } rounded-lg my-3 cursor-pointer`}
            >
              My Booking
            </p>
          </div>
          <div className="w-full my-3 ">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile_v2;
