import axios from "axios";
import Auth from "../middleware/storage";
import { push } from "connected-react-router";
import { paths } from "../routes/paths";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  // baseURL: `https://eadapi.easypay.ng/api/v1`,
  headers: {
    Accept: "application/json",
  },
  timeout: 120 * 1000,
});

// Request interceptor for API calls
apiClient.interceptors.request.use(
  async (config) => {
    const token = Auth.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = "application/json";
    config.timeout = 120000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
apiClient.interceptors.response.use(
  (response) => {
    if (response.data.hasError) {
      if (response.data.errors?.code === 401) {
        Auth.destroyToken();
        push(paths.SIGNIN);
        // push(paths.SIGIN);
      }
    }
    return response;
  },
  async function (error) {
    // const originalRequest = error.config;
    // const url = error.config.url !== `${process.env.BASE_URL}/auth/login`;
    // const refreshurl =
    //   error.config.url === `${process.env.BASE_URL}/auth/refresh-token`;
    console.log(error.config);
    // if (error.response.status === 401 && refreshurl) {
    //   return;
    // }

    return Promise.reject(error);
  }
);

export default apiClient;
