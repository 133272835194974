import React, { useEffect, useState } from "react";
import EditIcon from "../../../../components/icons/edit";
import ToggleButton from "../../../../components/custom/toggle-button";
import PropertyDetailCard from "../../../../components/custom/property-detail-card";
import { PropertyType } from "../../../../redux/types";
import { currencyFormat } from "../../../../utils/helper-functions";
import {
  InputField,
  TextAreaInput,
  TextInput,
} from "../../../../components/custom/text-input";
import { useFormFields } from "../../../../hooks/use-form-fields";
import { usePropertyForm } from "../../host-onboard/hooks/use-property-form";
import {
  CardCheckItem,
  SelectInput,
} from "../../../../components/custom/select-input";
import { useDispatch } from "react-redux";
import {
  fetchSingleProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import Location from "../../../../components/icons/location";
import CheckRedIcon from "../../../../components/icons/check-red";
import useGooglePlaces from "../../../../hooks/use-google-places";
import Modal from "../../../../components/custom/modal";
import { FormProvider, useForm } from "react-hook-form";
import useGooglePlaceInfo from "../../../../hooks/use-google-place-info";
import { countries } from "../../../../utils/static-data";
import CloseMobileIcon from "../../../../components/icons/close-mobile";
import MapView from "../../../../components/custom/map-view-location";

interface AboutPropertyProps {
  activeListing: PropertyType;
}

const AboutProperty: React.FC<AboutPropertyProps> = ({ activeListing }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const { propertyCategories } = usePropertyForm();
  const [formfields, setFieldValue] = useFormFields({
    name: activeListing?.name || "",
    description: activeListing?.description || "",
    price: activeListing?.price || "",
    address: activeListing?.address || "",
    sub_category_id: activeListing?.sub_category_id || "",
    google_location: activeListing?.google_location,
    city: activeListing?.city,
    country: activeListing?.country,
    latitude: activeListing?.latitude,
    longitude: activeListing?.longitude,
    hide_location: activeListing?.hide_location,
  });
  const [bathroomCount, setBathroomCount] = useState(
    activeListing?.bathrooms || 1
  );
  const [bedroomCount, setBedroomCount] = useState(
    activeListing?.bedrooms || 1
  );
  const [toiletCount, setToiletCount] = useState(activeListing?.toilets || 1);

  const [selectAmenities, setSelectedAmenities] = useState<number[]>([]);
  const methods = useForm();
  const [placeId, setPlaceId] = useState<string>("");
  const { places } = useGooglePlaces(formfields.address);
  const { place } = useGooglePlaceInfo(placeId);
  const [isFocus, setFocus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setFieldValue("name", activeListing?.name);
    setFieldValue("description", activeListing?.description);
    setFieldValue("price", activeListing?.price);
    setFieldValue("address", activeListing.address);
    setFieldValue("sub_category_id", activeListing?.sub_category_id);
    setBathroomCount(activeListing?.bathrooms);
    setBedroomCount(activeListing?.bedrooms);
    setToiletCount(activeListing?.toilets);
    if (activeListing?.property_amenities) {
      setSelectedAmenities([
        ...activeListing?.property_amenities?.map((item) => item.amenity.id),
      ]);
    }
    setToggle(activeListing.hide_location);

    //  eslint-disable-next-line
  }, [activeListing]);

  const handleAmenities = (id: number) => {
    if (selectAmenities.includes(id)) {
      setSelectedAmenities(selectAmenities.filter((x) => x !== id));
    } else {
      setSelectedAmenities([...selectAmenities, id]);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const onSaveChanges = async () => {
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing?.id,
        ...formfields,
        bathrooms: bathroomCount,
        toilets: toiletCount,
        bedrooms: bedroomCount,
        amenities: selectAmenities,
        hide_location: toggle,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing?.id));
      setEditMode(false);
    }
  };

  const toggleLocationVisibility = () => {
    setToggle(!toggle);
  };

  const handleSelect = (selectedOption: string) => {
    setFieldValue("sub_category_id", Number(selectedOption));
  };

  useEffect(() => {
    setCountry("Nigeria");
    methods.setValue("country", "Nigeria");
    if (place?.address_components) {
      for (let index = 0; index < place?.address_components.length; index++) {
        if (place?.address_components[index]?.types.includes("street_number")) {
          methods.setValue(
            "house",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("route")) {
          methods.setValue(
            "street",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (
          place?.address_components[index]?.types.includes(
            "administrative_area_level_2"
          )
        ) {
          methods.setValue(
            "town",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (
          place?.address_components[index]?.types.includes(
            "administrative_area_level_1"
          )
        ) {
          methods.setValue(
            "city",
            place?.address_components[index]?.long_name || ""
          );
        }
        if (place?.address_components[index]?.types.includes("country")) {
          methods.setValue(
            "country",
            place?.address_components[index]?.long_name || ""
          );
          setCountry(place?.address_components[index]?.long_name);
        }
      }
    }
    // eslint-disable-next-line
  }, [placeId, place]);

  const onSubmit = methods.handleSubmit(async (data) => {
    setFieldValue(
      "address",
      `${data.house},${data.street},${data.town},${data.city},${data.country}`
    );
    setFieldValue("city", data.city);
    setFieldValue("country", data.country);
    setFieldValue("google_location", JSON.stringify(place));
    // @ts-ignore
    setFieldValue("latitude", place?.geometry?.location?.lat);
    // @ts-ignore
    setFieldValue("longitude", place?.geometry?.location?.lng);
    setFieldValue("country", data.country);

    methods.reset();
    setIsModalOpen(false);
  });

  return (
    <div className="h-fit w-full dark:text-gray100 text-gray900 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex items-center justify-between mb-5">
        <span className="font-bold text-base">About this</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              onClick={onSaveChanges}
              className="bg-primary500 hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white"
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={handleEditClick}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <div className="flex flex-col gap-3">
          <div className="h-fit w-full text-gray900 dark:text-gray-100 border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-col gap-5">
            <TextInput
              label="Your listing title"
              placeholder="Enter title"
              onChange={(e) => setFieldValue("name", e.target.value)}
              type="text"
              value={formfields.name}
              className="lg:w-full text-base"
            />
            <TextAreaInput
              placeholder="Enter description"
              label="A few simple and unique sentences could go a long way."
              onChange={(e) => setFieldValue("description", e.target.value)}
              value={formfields.description}
              className="text-sm"
            />
          </div>
          <div className="h-fit w-full text-gray900 border border-gray400 dark:border-gray800 rounded-xl p-4 grid grid-cols-2 gap-5">
            <TextInput
              label="Price"
              placeholder="Enter price"
              onChange={(e) => setFieldValue("price", e.target.value)}
              type="number"
              value={formfields.price}
              className="lg:w-full"
            />

            <div className="flex flex-col gap-1">
              <DropdownTwo
                label="Property type"
                // @ts-ignore
                options={propertyCategories.subCategories.map((item) => {
                  return { value: item.id, label: item.name };
                })}
                // @ts-ignore
                onSelect={handleSelect}
                value={
                  propertyCategories.subCategories.filter(
                    (item) => item.id === activeListing?.sub_category_id
                  )[0].name
                }
              />
            </div>
          </div>
          <div className="h-fit w-full text-gray900 border border-gray400 dark:border-gray800 rounded-xl p-4 flex md:flex-row flex-col gap-5">
            <div className="flex flex-col gap-2 w-full ">
              {place && (
                <MapView
                  latitude={place.geometry.location.lat}
                  longitude={place.geometry.location.lng}
                />
              )}
              <label className="font-display text-xs dark:text-gray200">
                Enter address
              </label>
              <div className="flex items-center w-full gap-1 px-1 pl-2 rounded-full border placeholder:text-xs text-sm border-gray500 dark:bg-gray500">
                <input
                  type="text"
                  value={formfields.address}
                  onChange={(e) => setFieldValue("address", e.target.value)}
                  onFocus={() => setFocus(true)}
                  placeholder="Property Address"
                  className="border-transparent bg-transparent text-sm focus:border-transparent focus:outline-none focus:ring-0 w-full py-1"
                />
                <button
                  onClick={() => {
                    setFocus(false);
                    setFieldValue("address", formfields.address);
                  }}
                >
                  <CloseMobileIcon className="w-8" />
                </button>
              </div>

              {isFocus &&
                places?.map((place) => (
                  <div
                    key={place.place_id}
                    onClick={() => {
                      setPlaceId(place?.place_id);
                      setIsModalOpen(true);
                      setFocus(false);
                    }}
                    className={`w-auto flex items-center justify-between rounded-lg hover:bg-gray300 dark:hover:bg-gray700 p-2 flex-shrink-0 cursor-pointer`}
                  >
                    <div className="flex items-center gap-2 px-2 w-3/4">
                      <Location className="w-4 flex-shrink-0" />
                      <p className="text-xs">{place?.description}</p>
                    </div>
                    <CheckRedIcon className="w-3" />
                  </div>
                ))}
            </div>
            <div className="flex flex-col gap-1 md:w-[40%]">
              <div className="flex items-center justify-between mt-6">
                <span className="text-sm text-gray1000 font-medium">
                  Specify location in description
                </span>
                <ToggleButton
                  isActive={toggle}
                  onToggle={toggleLocationVisibility}
                />{" "}
              </div>
              <span className="text-xs text-gray700 dark:text-gray500 w-[85%]">
                We will only share specific location after you have been book
                (eg. Lekki, Lagos). You can turn on specific location if you
                like to make it clear to guest before booking eg. (Randle st,
                Lekki Lagos)
              </span>
            </div>
          </div>
          <div className="h-fit w-full text-gray900 border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-wrap gap-2">
            <PropertyDetailCard
              title="Bedrooms"
              count={bedroomCount}
              onDecrement={() =>
                bedroomCount > 1 && setBedroomCount(bedroomCount - 1)
              }
              onIncrement={() => setBedroomCount(bedroomCount + 1)}
              border={false}
            />
            <PropertyDetailCard
              title="Bathrooms"
              count={bathroomCount}
              onDecrement={() =>
                bathroomCount > 1 && setBathroomCount(bathroomCount - 1)
              }
              onIncrement={() => setBathroomCount(bathroomCount + 1)}
              border={false}
            />
            <PropertyDetailCard
              title="Toilets"
              count={toiletCount}
              onDecrement={() =>
                toiletCount > 1 && setToiletCount(toiletCount - 1)
              }
              onIncrement={() => setToiletCount(toiletCount + 1)}
              border={false}
            />
          </div>
          <div className="h-fit w-full text-gray900 border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-col gap-1">
            <span className="text-sm text-gray1000 font-medium">Amenities</span>
            <div className="flex flex-wrap items-center lg:gap-2 gap-1">
              {propertyCategories.amenities.map((item, index) => (
                <CardCheckItem
                  key={index}
                  icon={item.icon_tag}
                  onSelect={() => handleAmenities(item.id)}
                  title={item.name}
                  isSelected={selectAmenities.includes(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <span className="font-medium text-gray800 dark:text-gray200 text-base">
            {activeListing?.name}
          </span>
          <span className="text-gray700 dark:text-gray100 text-base">
            {currencyFormat(activeListing?.price || 0)} per night
          </span>
          <div className="flex items-center gap-2 text-sm">
            <span
              className={`${
                activeListing?.is_live
                  ? "text-green-500 bg-gray-100 dark:bg-gray900 dark:text-gray100"
                  : "text-primary500 bg-primary100 dark:bg-primary500/50 dark:text-gray100"
              } rounded-full px-2 text-xs`}
            >
              {activeListing?.is_live ? "Published" : "Pending"}
            </span>{" "}
            <span
              className={`${
                activeListing?.is_verified
                  ? "text-blue-500 bg-blue-100 dark:text-blue-100 dark:bg-blue-500/50"
                  : "text-gray900 bg-gray400 dark:text-gray200 dark:bg-gray1000"
              } rounded-full px-2 text-xs`}
            >
              {activeListing?.is_verified ? "Verified" : "Unverified"}
            </span>
          </div>
          {activeListing &&
            activeListing.latitude &&
            activeListing.longitude && (
              <MapView
                latitude={activeListing.latitude}
                longitude={activeListing.longitude}
              />
            )}
        </>
      )}

      <Modal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        className="lg:w-[50%] p-2"
      >
        <div className="flex flex-col items-center mx-2 ">
          <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
            Confirm your address
          </p>
          <div className="w-full lg:w-4/5">
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => e.preventDefault()}
                noValidate
                autoComplete="off"
              >
                <SelectInput
                  label="Country"
                  id="country"
                  placeholder="Select your country"
                  onChange={(text: any) => setCountry(text.target.value)}
                  data={countries}
                  value={country}
                />
                <hr className="my-2 border-gray400" />
                <div className="lg:flex items-center justify-between mb-2 gap-2">
                  <InputField
                    label="Street address"
                    placeholder="Street address"
                    type="text"
                    name="street"
                    validation={{
                      required: {
                        value: true,
                        message: "Street cannot be empty",
                      },
                    }}
                  />
                  <InputField
                    label="Exact Floor, flat number, house number"
                    placeholder="House number"
                    type="text"
                    name="house"
                    validation={{
                      required: {
                        value: true,
                        message: "House number cannot be empty",
                      },
                    }}
                  />
                </div>
                <div className="lg:flex items-center justify-between mb-2 gap-2">
                  <InputField
                    label="City"
                    placeholder="City"
                    type="text"
                    name="city"
                    validation={{
                      required: {
                        value: true,
                        message: "City cannot be empty",
                      },
                    }}
                  />
                  <InputField
                    label="Town"
                    placeholder="Town"
                    type="text"
                    name="town"
                    validation={{
                      required: {
                        value: true,
                        message: "Town cannot be empty",
                      },
                    }}
                  />
                </div>
              </form>
            </FormProvider>
            <hr className="my-2 border-gray400" />
            <div className="flex justify-between items-start gap-2">
              <div>
                <p className="font-display text-xs font-bold text-gray1000">
                  Specify location in description
                </p>
                <p className="font-display text-xs font-medium text-gray700">
                  We will only share specific location after you have been book
                  (eg. Lekki, Lagos). You can turn on specific location if you
                  like to make it clear to guest before booking eg. (Randle st,
                  Lekki Lagos)
                </p>
              </div>
              <button>
                <ToggleButton
                  isActive={toggle}
                  onToggle={() => setToggle(!toggle)}
                />
              </button>
            </div>
          </div>
          {/* navigation */}
          <div className="flex gap-3 items-center py-4">
            <button
              onClick={() => {
                setIsModalOpen(false);
                methods.reset();
              }}
              className={`flex text-xs font-display font-semibold py-1 px-2 rounded-full shadow ${"bg-gray500"} items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className={`flex text-xs font-display font-semibold py-1 px-2 rounded-full shadow ${"bg-primary500"} items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AboutProperty;
