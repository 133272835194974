import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const ReviewStars: React.FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);

  return (
    <div className="flex">
      {[...Array(5)].map((_, index) => {
        const starValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={starValue}
              onClick={() => setRating(starValue)}
              className="hidden"
            />
            <FaStar
              size={30}
              className={`cursor-pointer transition-all duration-300 ease-in-out mr-2 ${
                starValue <= (hover || rating)
                  ? "text-amber-400"
                  : "text-gray-300"
              }`}
              onMouseEnter={() => setHover(starValue)}
              onMouseLeave={() => setHover(0)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default ReviewStars;
