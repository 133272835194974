import Navbar from "../../../components/custom/navbar";
import "./styles.scss";
import Footer from "../../../components/custom/footer";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import Location from "../../../components/icons/location";
import Bedroom from "../../../components/icons/bedroom";
// import ReviewsCard from "../../../components/custom/reviews-card";
import SearchIcon from "../../../components/icons/search";
import CircleCircle from "../../../components/icons/circle-circle";
import CloseMobileIcon from "../../../components/icons/close-mobile";
import { useEffect, useMemo, useState } from "react";
import ModalSideBar from "../../../components/custom/modal-side-menu";
import FilterSection from "./components/filter-section";
import {
  Configure,
  Hits,
  InstantSearch,
  Pagination,
  SortBy,
  Stats,
  useInstantSearch,
} from "react-instantsearch";
import BreadcrumbsTwo from "../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../routes/paths";
import SearchListing from "../../../components/custom/search-item-listing";
import { usePropertyForm } from "../host-onboard/hooks/use-property-form";
// import { MapContainer, TileLayer } from "react-leaflet";
import { FaXmark } from "react-icons/fa6";
import { searchClient } from "../../../plugins/algolia-client";
import useQuery from "../../../hooks/use-query";
import useGooglePlaces from "../../../hooks/use-google-places";
import CheckRedIcon from "../../../components/icons/check-red";
import SpecialReservations from "../../../components/custom/special-reservations";
import {
  // AdvancedMarker,
  APIProvider,
  Map,
  Marker,
  // Pin,
  // useMarkerRef,
} from "@vis.gl/react-google-maps";
import { currencyFormat } from "../../../utils/helper-functions";
import { PropertyType } from "../../../redux/types";
import SearchListingMap from "../../../components/custom/search-item-listing-map";
import useGooglePlaceInfo from "../../../hooks/use-google-place-info";
import { LuSettings2 } from "react-icons/lu";
import {
  bedroomOptions,
  guestOptions,
  priceOptions,
} from "../../../utils/static-data";
import DropdownFilter from "../../../components/custom/drop-down-filter";

function LoadingIndicator() {
  const { status } = useInstantSearch();

  if (status === "stalled") {
    return (
      <div className="animate-pulse w-full flex flex-col gap-4">
        <div className="flex gap-3 w-full">
          <div className="flex flex-col gap-2 w-1/2">
            <div className="rounded-xl h-48 w-full bg-gray600"></div>
          </div>
          <div className="w-full flex flex-col justify-between">
            <div className="h-2 w-24 bg-gray600 rounded"></div>
            <div className="h-2 w-36 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="flex items-center justify-between">
              <div className="h-2 w-36 bg-gray600 rounded"></div>
              <div className="h-2 w-36 bg-gray600 rounded"></div>
            </div>
          </div>
        </div>
        <div className="flex gap-3 w-full">
          <div className="flex flex-col gap-2 w-1/2">
            <div className="rounded-xl h-48 w-full bg-gray600"></div>
          </div>
          <div className="w-full flex flex-col justify-between">
            <div className="h-2 w-24 bg-gray600 rounded"></div>
            <div className="h-2 w-36 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="h-2 w-1/2 bg-gray600 rounded"></div>
            <div className="flex items-center justify-between">
              <div className="h-2 w-36 bg-gray600 rounded"></div>
              <div className="h-2 w-36 bg-gray600 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

function Hit({ hit }: any) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [hit]);
  return (
    <SearchListing
      pic={hit?.property_media?.[0]?.url}
      price={hit?.price}
      title={hit?.name}
      description={hit?.description}
      location={hit?.address}
      hostname={`${hit?.host?.first_name} ${hit?.host?.last_name}`}
      to={`${paths.VIEW_PROPERTY}/${hit?.slug}`}
      item={hit}
      hit={hit}
    />
  );
}

const SearchList = () => {
  const [isOpenSide, setOpenSide] = useState(false);
  const query = useQuery();
  const [bedroomFilter, setBedroomFilter] = useState("bedrooms > 0");
  const [priceFilter, setPriceFilter] = useState("(price >= 1000)");
  const [guestFilter, setGuestFilter] = useState("guests > 0");
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(
    "sub_category_id = 1"
  );
  const [searchText, setSearchText] = useState("");
  const { places } = useGooglePlaces(searchText);
  const [locations, setLocations] = useState<any[]>([]);
  const [allFilter, setAllfilter] = useState({
    bedroomCount: null,
    propertyTypeFilter: null,
    maxPrice: null,
    minPrice: null,
  });
  const { propertyCategories } = usePropertyForm();
  const [mapview, setMapView] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const [propertyMarked, setPropertyMarked] = useState<PropertyType | null>(
    null
  );
  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [placeId, setPlaceId] = useState<string>("");

  const { place } = useGooglePlaceInfo(placeId);

  function CustomMark({ hit }: any) {
    return (
      <Marker
        key={hit.id}
        position={{ lat: hit.latitude, lng: hit.longitude }}
        label={{
          text: currencyFormat(hit.price || 0),
          className:
            "text-sm bg-eadWhite p-1 shadow rounded-full z-50 font-semibold",
        }}
        onClick={() => setPropertyMarked(hit)}
      />
    );
  }

  useEffect(() => {
    setBedroomFilter(
      `${
        allFilter.bedroomCount
          ? `bedrooms = ${allFilter.bedroomCount}`
          : "bedrooms >= 0"
      }`
    );
    setPropertyTypeFilter(
      `${
        allFilter.propertyTypeFilter
          ? `sub_category_id = ${allFilter.propertyTypeFilter}`
          : ""
      }`
    );
    setPriceFilter(
      `${
        allFilter.maxPrice || allFilter.minPrice
          ? `(price >= ${allFilter.minPrice} AND price < ${allFilter.maxPrice})`
          : "(price >= 1000)"
      }`
    );

    // eslint-disable-next-line
  }, [allFilter]);

  useEffect(() => {
    if (query.get("citySearch")) {
      setSearchText(query.get("citySearch") || "");
    }
    if (query.get("bedroom")) {
      setBedroomFilter(`bedrooms = ${query.get("bedroom")}`);
    }
    if (query.get("latitude") && query.get("longitude")) {
      setLocationFilter(`${query.get("latitude")}, ${query.get("longitude")}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchText.length > 2 && places.length > 0) {
      setLocations([...places]);
    }
  }, [searchText, places]);

  useEffect(() => {
    if (place?.geometry.location.lat && place?.geometry.location.lng) {
      setLocationFilter(
        `${place?.geometry.location.lat}, ${place?.geometry.location.lng}`
      );
    }
  }, [placeId, place]);

  const queryFilterParams = useMemo(
    () =>
      `${bedroomFilter} AND ${guestFilter} AND ${priceFilter}${
        propertyTypeFilter ? " AND " + propertyTypeFilter : ""
      }`,
    [bedroomFilter, guestFilter, propertyTypeFilter, priceFilter]
  );

  const resetFilters = () => {
    setBedroomFilter("bedrooms >= 0");
    setGuestFilter("guests > 0");
    setPriceFilter("(price >= 1000)");
    setPropertyTypeFilter("");
  };

  return (
    <div>
      <div className="bg-eadWhite relative pb-18 px-4 dark:bg-gray1000">
        <div className="max-w-screen-2xl mx-auto">
          <Navbar />
        </div>
        <div className="max-w-screen-lg mx-auto lg:mt-10">
          <div className="flex items-center justify-between">
            <BreadcrumbsTwo name={"Search result"} path={paths.SEARCH_LIST} />
          </div>

          <hr className="border-gray400 my-6 hidden lg:block dark:border-gray800" />

          <InstantSearch searchClient={searchClient} indexName="properties">
            <Configure
              analytics={false}
              filters={queryFilterParams}
              hitsPerPage={10}
            />
            {locationFilter && (
              <Configure
                analytics={false}
                hitsPerPage={20}
                aroundLatLng={locationFilter}
              />
            )}
            <div className="relative">
              {/* buttons and the likes */}
              <div className="flex md:flex-row flex-col justify-between items-center w-full">
                {/* search button */}
                <div className="relative md:mr-3 md:w-auto w-full md:py-0 py-3 pr-3 md:border-r-2 border-gray300 dark:border-gray800">
                  <div className="flex items-center border border-gray400 dark:border-gray800 rounded-full p-1 pl-2 h-10 gap-2 flex-shrink-0 no-scrollbar">
                    <SearchIcon />
                    <input
                      type="text"
                      value={searchText}
                      onChange={(event) =>
                        setSearchText(event.currentTarget.value)
                      }
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      placeholder="Search location"
                      className="border-transparent bg-transparent text-sm focus:border-transparent focus:outline-none focus:ring-0 w-full py-1 dark:text-gray200"
                    />
                    {searchText.length > 0 && (
                      <button
                        onClick={() => {
                          setFocus(false);
                          setSearchText("");
                          setLocationFilter(null);
                        }}
                      >
                        <CloseMobileIcon className="w-8" />
                      </button>
                    )}
                  </div>
                </div>
                {/* mobile */}
                <div className="w-screen h-10 mb-16 relative md:hidden flex">
                  <div className="flex items-center justify-between w-full px-4">
                    <div className="flex items-center border border-gray400 rounded-full p-2 gap-1">
                      <p className="text-sm font-display dark:text-gray200">
                        Sort:
                      </p>
                      <SortBy
                        defaultValue="properties"
                        items={[
                          { value: "properties", label: "Most Recent" },
                          {
                            value: "properties_price_desc",
                            label: "Highest Price",
                          },
                          {
                            value: "properties_price_asc",
                            label: "Lowest Price",
                          },
                        ]}
                        classNames={{
                          select:
                            "focus:outline-none focus:ring-0 dark:text-gray200 dark:bg-gray1000 text-sm bg-transparent",
                        }}
                      />
                    </div>
                    <div
                      onClick={() => setMapView((prev) => !prev)}
                      className="flex gap-1 items-center bg-gray400 rounded-full p-2 cursor-pointer dark:text-gray200 dark:bg-gray1000 dark:border-gray200 dark:border"
                    >
                      <p className="text-sm font-display">Map view</p>
                      {mapview ? <FaXmark width="30" /> : <Location />}
                    </div>
                  </div>
                  <div className="h-[100vh] mt-12 w-screen absolute overflow-x-scroll top-0 px-4">
                    <div className="flex items-center gap-2 flex-grow justify-start md:justify-end">
                      <div className="min-w-36">
                        <DropdownFilter
                          options={propertyCategories.subCategories.map(
                            (item) => ({
                              name: item.name,
                              value: `sub_category_id = ${item.id}`,
                            })
                          )}
                          onSelect={(value) => setPropertyTypeFilter(value)}
                          value={propertyTypeFilter}
                          defaultValue="Type"
                        />
                      </div>
                      <div className="min-w-36">
                        <DropdownFilter
                          icon={<Bedroom />}
                          options={bedroomOptions}
                          onSelect={(value) => setBedroomFilter(value)}
                          value={bedroomFilter}
                        />
                      </div>
                      <div className="min-w-36">
                        <DropdownFilter
                          options={guestOptions}
                          onSelect={(value) => setGuestFilter(value)}
                          value={guestFilter}
                        />
                      </div>
                      <div className="min-w-38">
                        <DropdownFilter
                          icon={<CircleCircle />}
                          options={priceOptions}
                          onSelect={(value) => setPriceFilter(value)}
                          value={priceFilter}
                        />
                      </div>
                      <div className="flex items-center gap-1 h-10">
                        <div
                          onClick={() => setOpenSide(true)}
                          className="flex cursor-pointer items-center bg-gray400 rounded-full h-full px-3 gap-2 dark:text-gray200 dark:bg-gray900"
                        >
                          <LuSettings2 />
                          <p className="text-sm font-bold font-display">
                            Filters
                          </p>
                        </div>
                        <div className="cursor-pointer" onClick={resetFilters}>
                          <CloseMobileIcon className="h-full" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:flex hidden items-center gap-2 flex-grow justify-start md:justify-end">
                  <div className="min-w-36">
                    <DropdownFilter
                      options={propertyCategories.subCategories.map((item) => ({
                        name: item.name,
                        value: `sub_category_id = ${item.id}`,
                      }))}
                      onSelect={(value) => setPropertyTypeFilter(value)}
                      value={propertyTypeFilter}
                      defaultValue="Type"
                    />
                  </div>
                  <div className="min-w-36">
                    <DropdownFilter
                      icon={<Bedroom />}
                      options={bedroomOptions}
                      onSelect={(value) => setBedroomFilter(value)}
                      value={bedroomFilter}
                    />
                  </div>
                  <div className="min-w-36">
                    <DropdownFilter
                      options={guestOptions}
                      onSelect={(value) => setGuestFilter(value)}
                      value={guestFilter}
                    />
                  </div>
                  <div className="min-w-36">
                    <DropdownFilter
                      icon={<CircleCircle />}
                      options={priceOptions}
                      onSelect={(value) => setPriceFilter(value)}
                      value={priceFilter}
                    />
                  </div>
                  <div className="flex flex-grow items-center gap-1 h-10">
                    <div
                      onClick={() => setOpenSide(true)}
                      className="flex cursor-pointer items-center bg-gray400 rounded-full h-full px-3 gap-2 dark:text-gray200 dark:bg-gray900"
                    >
                      <LuSettings2 />
                      <p className="text-sm font-bold font-display">Filters</p>
                    </div>
                    <div className="cursor-pointer" onClick={resetFilters}>
                      <CloseMobileIcon className="h-full" />
                    </div>
                  </div>
                </div>
              </div>

              {/* the search result */}
              <div className="absolute top-15 left-0 bg-gray200 z-50 w-full items-center dark:bg-gray1000">
                {isFocus && locations.length === 0 && (
                  <div className="h-18 flex items-center justify-center py-5">
                    <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 border-r-primary500" />
                  </div>
                )}
                {isFocus &&
                  locations?.map((place) => (
                    <div
                      key={place.place_id}
                      onClick={() => {
                        setSearchText(place?.description);
                        setPlaceId(place.place_id);
                        setFocus(false);
                        setLocations([]);
                      }}
                      className={`w-full flex items-center justify-between rounded-lg hover:bg-gray400 p-2 flex-shrink-0 cursor-pointer dark:text-gray200 dark:hover:text-gray1000`}
                    >
                      <div className="flex items-center gap-2 px-2 w-3/4">
                        <Location className="w-4 flex-shrink-0" />
                        <p className="text-sm">{place?.description}</p>
                      </div>
                      <CheckRedIcon className="w-3" />
                    </div>
                  ))}
              </div>
            </div>
            <div className="md:flex hidden items-center justify-between my-6">
              <div className="flex items-center border border-gray400 rounded-full p-2 gap-1">
                <p className="text-sm font-display dark:text-gray200">Sort:</p>
                <SortBy
                  defaultValue="properties"
                  items={[
                    { value: "properties", label: "Most Recent" },
                    { value: "properties_price_desc", label: "Highest Price" },
                    { value: "properties_price_asc", label: "Lowest Price" },
                  ]}
                  classNames={{
                    select:
                      "focus:outline-none focus:ring-0 dark:text-gray200 dark:bg-gray1000 text-sm",
                  }}
                />
              </div>
              <div
                onClick={() => setMapView((prev) => !prev)}
                className="flex gap-1 items-center bg-gray400 rounded-full p-2 cursor-pointer dark:text-gray200 dark:bg-gray1000 dark:border-gray200 dark:border"
              >
                <p className="text-sm font-display">Map view</p>
                {mapview ? <FaXmark width="30" /> : <Location />}
              </div>
            </div>
            <Stats className="dark:text-gray200 my-3" />
            <LoadingIndicator />
            {mapview ? (
              <div className="w-[100%] h-[70%] relative items-center justify-center">
                <APIProvider apiKey={process.env.REACT_APP_APIKEY || ""}>
                  <Map
                    style={{ width: "100%", height: "500px" }}
                    defaultCenter={{ lat: 6.465422, lng: 3.406448 }}
                    defaultZoom={12}
                  >
                    <Hits hitComponent={CustomMark} />
                  </Map>
                </APIProvider>
                <div className="mx-auto lg:w-[70%]  lg:absolute bottom-0 left-40">
                  {propertyMarked && (
                    <SearchListingMap
                      pic={propertyMarked?.property_media?.[0]?.url}
                      price={propertyMarked?.price}
                      title={propertyMarked?.name}
                      description={propertyMarked?.description}
                      location={propertyMarked?.address}
                      hostname={`${propertyMarked?.host?.first_name} ${propertyMarked?.host?.last_name}`}
                      to={`${paths.VIEW_PROPERTY}/${propertyMarked?.slug}`}
                      item={propertyMarked}
                      hit={propertyMarked}
                      onReset={() => setPropertyMarked(null)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="lg:flex flex-col">
                <Hits hitComponent={Hit} />
              </div>
            )}
            <div className="my-8">
              <Pagination
                classNames={{
                  root: "lg:m-auto lg:w-full",
                  list: "flex w-full gap-1 lg:gap-10 justify-center",
                  item: "cursor-pointer dark:text-gray200 py-2 px-4",
                  selectedItem:
                    "bg-primary300 py-2 px-4 rounded-full text-gray200",
                }}
                translations={{
                  previousPageItemText: "Previous",
                  nextPageItemText: "Next",
                }}
                showFirst={false}
                showLast={false}
              />
            </div>
            <div className="lg:hidden"></div>
          </InstantSearch>

          <hr className="border-gray400 my-6 dark:border-gray800" />
          {/* reservations */}
          <SpecialReservations />
        </div>
      </div>

      <ModalSideBar open={isOpenSide} onClose={() => setOpenSide(false)}>
        <FilterSection
          updateFilter={(pa) => setAllfilter(pa)}
          closeModal={() => setOpenSide(false)}
        />
      </ModalSideBar>

      {/* make bookings */}
      <MakeBookingSection />
      {/* footer */}
      <Footer />
    </div>
  );
};

export default SearchList;
