import StarPrimary from "../../../../components/icons/star-primary";
import ArrowIcon from "../../../../components/icons/arrow-icon";


interface ActivityCardProps {
  data: { title: string; count: string | number; percentage: number }[];
}

const ActivityCard: React.FC<ActivityCardProps> = ({ data }) => {
  return (
    <div className="grid md:grid-cols-3 gap-5">
      {data.map((item, index) => {
        const isOverallRating = item.title === "Overall Ratings";
        const isIncrease = item.percentage > 0;
        const arrowColor = isIncrease ? "#219653" : "#EB5757";
        const textColor = isIncrease ? "#219653" : "#EB5757";
        const bgClass = isOverallRating ? "bg-yellow-100 dark:bg-yellow-400/10" : "bg-gray200 dark:bg-transparent";
        const borderClass = isOverallRating
          ? "border-yellow-200 dark:border-yellow-300"
          : "border-gray400 dark:border-gray800";

        return (
          <div
            key={index}
            className={`${bgClass}  border flex flex-col gap-3 ${borderClass} rounded-xl min-h-24 p-4`}
          >
            <span className="font-medium">{item.title}</span>
            <div className="flex items-center gap-2">
              <h1 className="text-3xl break-words font-bold">{item.count}</h1>
              {isOverallRating && <StarPrimary />}
            </div>

            <span className="text-gray800 dark:text-gray100 font-medium flex items-center gap-1">
              <span className="flex items-center gap-1">
                <ArrowIcon isIncrease={isIncrease} arrowColor={arrowColor} />
                <span style={{ color: textColor }}>{item.percentage}%</span>
              </span>
              <span> vs last month</span>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ActivityCard;
