// ArrowIcon.tsx
import React, { FC } from "react";

interface ArrowIconProps {
  isIncrease: boolean;
  arrowColor: string;
}

const ArrowIcon: FC<ArrowIconProps> = ({ isIncrease, arrowColor }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: isIncrease ? "rotate(0deg)" : "rotate(180deg)",
        stroke: arrowColor,
      }}
    >
      <path
        d="M6.00002 10.3333L6.00002 1M6.00002 1L1.33335 5.66667M6.00002 1L10.6667 5.66667"
        stroke={arrowColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
