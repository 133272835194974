import React from "react";

type Props = {
  color?: string;
  className?: string;
};

const EditIcon = ({ color, className, ...rest }: Props) => (
  <svg
    className={`flex-shrink-0 h-4 w-4 ${className}`}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-gray100"
      d="M16.836 8.83234L12.836 4.83234M1.33594 20.3323L4.72031 19.9563C5.1338 19.9104 5.34054 19.8874 5.53378 19.8248C5.70523 19.7693 5.86838 19.6909 6.01882 19.5917C6.18839 19.4799 6.33548 19.3328 6.62966 19.0386L19.836 5.83234C20.9405 4.72777 20.9405 2.9369 19.836 1.83233C18.7314 0.727765 16.9405 0.727763 15.836 1.83233L2.62967 15.0386C2.33548 15.3328 2.18839 15.4799 2.07657 15.6494C1.97737 15.7999 1.89895 15.963 1.84345 16.1345C1.78089 16.3277 1.75792 16.5345 1.71198 16.948L1.33594 20.3323Z"
      stroke="#525252"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default EditIcon;
