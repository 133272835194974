import React, { useRef } from "react";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

type Props = {
  slides: { url: string; title: string }[];
  classNameImg?: string;
};

const Carousel = ({ slides, classNameImg }: Props) => {
  const flickityRef = useRef<Flickity | null>(null);

  const previousSlide = () => {
    if (flickityRef.current) {
      flickityRef.current.previous();
    }
  };

  const nextSlide = () => {
    if (flickityRef.current) {
      flickityRef.current.next();
    }
  };

  return (
    <div className="relative">
      {slides.length === 0 ? (
        <div className="animate-pulse w-full">
          <div className="rounded-xl h-40 w-full bg-gray600"></div>
        </div>
      ) : (
        <Flickity
          flickityRef={(ref) => (flickityRef.current = ref)}
          className="carousel"
          elementType="div"
          options={{
            wrapAround: true,
            draggable: true,
          }}
          disableImagesLoaded={false}
          reloadOnUpdate
        >
          {slides?.map((s, index) => (
            <img
              key={index}
              src={s.url}
              className={classNameImg || "object-cover"}
              alt={s.title}
            />
          ))}
        </Flickity>
      )}
      {/* Custom Arrow Buttons */}
      <button
        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-3xl text-white"
        onClick={previousSlide}
      >
        <div className="rounded-full flex items-center justify-center text-gray700 border bg-white border-gray400 p-2 w-10 h-10">
          <IoIosArrowRoundBack className="w-full h-full object-cover" />
        </div>
      </button>
      <button
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-3xl text-white"
        onClick={nextSlide}
      >
        <div className="rounded-full flex items-center justify-center text-gray700 border bg-white border-gray400 p-2 w-10 h-10">
          <IoIosArrowRoundForward className="w-full h-full object-cover" />
        </div>{" "}
      </button>
    </div>
  );
};

export default Carousel;
