import { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import PaymentInfo from "./PaymentInfo";
import SecurityInfo from "./SecurityInfo";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../../routes/paths";
import useQuery from "../../../../hooks/use-query";

const Settings = () => {
  const params = useQuery();
  const [settings, setSettings] = useState(params.get("tab") || "Personal");

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo name="Settings" path={`${paths.PROFILE}/settings`} />
      </div>
      <div className="flex items-center gap-3">
        <p
          onClick={() => setSettings("Personal")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 ${
            settings === "Personal" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Personal info
        </p>
        <p
          onClick={() => setSettings("Payment")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 ${
            settings === "Payment" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Payment method
        </p>
        <p
          onClick={() => setSettings("Security")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 ${
            settings === "Security" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Login & security
        </p>
      </div>
      <hr className="border-gray400 dark:border-gray800  my-3" />
      <div className="w-full bg-gray300 dark:bg-gray1000 dark:border-gray800 border border-gray400 rounded-xl flex flex-col items-center p-3">
        <div className="lg:w-3/4 w-full">
          {settings === "Personal" && <PersonalInfo />}
          {settings === "Payment" && <PaymentInfo />}
          {settings === "Security" && <SecurityInfo />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
