import Avatar from "../icons/avatar";
import Emailicon from "../icons/email";
import Whatsapp from "../icons/whatsapp";
import Phoneicon from "../icons/phone";
// import HeartEmpty from "../icons/heart-empty";
import Award from "../icons/award";
import { Link } from "react-router-dom";
import { currencyFormat, truncate } from "../../utils/helper-functions";
import { PropertyType } from "../../redux/types";
import { Highlight } from "react-instantsearch";
import CloseMobileIcon from "../icons/close-mobile";
// import Carousel from "./carousel";

type Props = {
  price: string;
  icon?: any;
  pic?: string;
  title?: string;
  description?: string;
  location?: string;
  hostname?: string;
  to?: string;
  item?: PropertyType;
  hit?: any;
  onReset?: () => void;
};

const SearchListingMap = ({
  title,
  icon,
  pic,
  price,
  description,
  location,
  hostname,
  to,
  item,
  hit,
  onReset,
}: Props) => {
  return (
    <div className="lg:flex my-4 gap-2 items-center relative w-full flex-shrink-0 bg-eadWhite dark:bg-gray1000 p-2 rounded-lg shadow-lg">
      <img src={pic} className="h-44 lg:w-60 w-full rounded-lg" alt="" />
      <div className="flex flex-col w-full">
        <div className="lg:flex items-center justify-between hidden">
          <div className="flex gap-1 rounded-full bg-primary100 items-center p-1">
            <Award />
            <span className="text-[10px] font-display text-primary500">
              Rare find
            </span>
          </div>
          <button onClick={onReset} className="z-50">
            <CloseMobileIcon color="#525252" className="w-8 mb-3" />
          </button>
          {/* <ArrowTopRight /> */}
        </div>
        <Link to={to || "#"} target="_blank" className="flex items-center gap-2 mt-2">
          <div>
            <p className="text-lg font-display font-bold dark:text-gray200">
              {currencyFormat(price || 0)}
            </p>
            <p className="text-xs font-display text-gray900 dark:text-gray200">
              per night
            </p>
          </div>
        </Link>
        <p className="font-display text-base font-bold text-gray900 dark:text-gray200">
          <Highlight attribute="name" hit={hit} />
        </p>
        <div className="flex flex-col gap-1">
          <p className="text-xs text-gray800 font-display dark:text-gray200">
            {truncate(description || "", 100)}
          </p>
          <Link to={to || "#"} target="_blank">
            <div className="lg:flex items-center justify-between hidden">
              <div className="flex items-center gap-1 mt-1">
                <Avatar className="h-4" />
                <p className="text-[10px] font-display text-gray-800 dark:text-gray200">
                  Hosted by {hostname}
                </p>
              </div>
              <div className="flex gap-1">
                <Link
                  className="flex items"
                  to={`mailto:${item?.contact_email}?subject=Bookings&body=Hello%20i%20want%20to%20book%20this%20property%20${item?.share_link}`}
                >
                  <Emailicon className="h-4" />
                  <span className="text-[10px] font-display dark:text-gray200">
                    Email
                  </span>
                </Link>
                <Link
                  className="flex items-center"
                  to={`https://wa.me/08054062639?text=Hello i want to book this property ${item?.share_link}`}
                  target="_blank"
                >
                  <Whatsapp className="h-4" />
                  <span className="text-[10px] font-display dark:text-gray200">
                    Whatsapp
                  </span>
                </Link>
                <Link
                  className="flex items-center"
                  to={`tel:${item?.contact_phone_number}`}
                >
                  <Phoneicon className="h-4" />
                  <span className="text-[10px] font-display dark:text-gray200">
                    Call
                  </span>
                </Link>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchListingMap;
