import { useRef, useEffect } from "react";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useState } from "react";
import Modal from "../../components/custom/modal";

interface ActiveListing {
  blockedDates?: Date[];
}

interface CalendarProps {
  editable?: boolean;
  updateActiveListing?: (listing: ActiveListing) => void;
  activeListing?: ActiveListing;
}

const Calendar: React.FC<CalendarProps> = ({
  editable = false,
  updateActiveListing,
  activeListing,
}) => {
  const today = startOfToday();
  const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const colStartClasses = [
    "col-start-7", // Sunday
    "col-start-1", // Monday
    "col-start-2", // Tuesday
    "col-start-3", // Wednesday
    "col-start-4", // Thursday
    "col-start-5", // Friday
    "col-start-6", // Saturday
  ];

  const modalRef = useRef<HTMLDivElement>(null);

  const [events] = useState([
    new Date(2024, 5, 1),
    new Date(2024, 5, 10),
    new Date(2024, 5, 15),
    new Date(2024, 5, 20),
  ]);

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  useEffect(() => {
    if (activeListing?.blockedDates) {
      setSelectedDates(activeListing.blockedDates);
    }
  }, [activeListing]);

  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayOfMonth), { weekStartsOn: 1 }),
  });

  const getPrevMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
  };

  const getNextMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
  };

  const isEventDay = (day: Date) => {
    return events.some(
      (event) => isSameMonth(event, day) && event.getDate() === day.getDate()
    );
  };

  const handleDayClick = (day: Date) => {
    if (editable) {
      setIsModalOpen(true);
      document.body.style.overflowY = "hidden";
    }
  };

  const handleModalDayClick = (day: Date) => {
    setSelectedDates((prevDates) => {
      if (prevDates.some((d) => d.getTime() === day.getTime())) {
        return prevDates.filter((d) => d.getTime() !== day.getTime());
      } else {
        return [...prevDates, day];
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleConfirmDates = () => {
    if (updateActiveListing) {
      updateActiveListing({ blockedDates: selectedDates });
    }
    closeModal();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="flex items-center justify-center">
      <div className="w-full flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between w-full">
            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getPrevMonth}
            >
              <FaChevronLeft />
            </button>
            <p className="font-semibold text-sm md:text-lg text-gray900 dark:text-gray100">
              {format(firstDayOfMonth, "MMMM yyyy")}
            </p>

            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getNextMonth}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-7 place-items-center">
          {days.map((day, idx) => (
            <div key={idx} className="font-semibold md:text-base text-xs">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-3 place-items-center">
          {daysInMonth.map((day, idx) => (
            <div
              key={idx}
              className={`${colStartClasses[getDay(day)]} relative`}
            >
              <p
                className={`cursor-pointer md:text-base text-xs transition-all duration-200 ease-in-out flex items-center justify-center rounded-full w-7 h-7 md:h-10 md:w-10 ${
                  editable &&
                  selectedDates.some((d) => d.getTime() === day.getTime())
                    ? "bg-gray400 dark:bg-gray800 dark:text-gray100 text-gray1000"
                    : isSameMonth(day, today)
                    ? "text-gray900 dark:text-gray100"
                    : "text-gray600 "
                } ${isSameMonth(day, today) ? "text-gray900" : "text-gray600 "}
                 ${
                   !isToday(day) &&
                   "hover:bg-primary200 dark:hover:bg-primary500/30"
                 } ${
                  isToday(day) && "bg-red-500 dark:bg-[#FF3333] text-white"
                } ${
                  isEventDay(day) &&
                  "after:absolute after:content-[''] after:h-1 after:w-1 md:after:h-1.5 md:after:w-1.5 after:bg-green-500 dark:bg-green-500/20 after:rounded-full after:-bottom-1 md:after:bottom-1 bg-green-50"
                }`}
                onClick={() => handleDayClick(day)}
              >
                {format(day, "d")}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Modal className="bg-transparent" open={isModalOpen} onClose={closeModal}>
        <div className="rounded-lg p-6 w-full max-w-md" ref={modalRef}>
          <h2 className="text-xl font-semibold">Selected blockout date(s)</h2>
          <p className="mt-1">Add days when you do not want to get bookings.</p>
          <div className="flex items-center justify-between w-full mt-5 ">
            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getPrevMonth}
            >
              <FaChevronLeft />
            </button>
            <p className="font-semibold text-sm md:text-lg text-gray900 dark:text-gray100">
              {format(firstDayOfMonth, "MMMM yyyy")}
            </p>

            <button
              className="cursor-pointer text-sm text-gray600  hover:text-gray900 transition-all duration-300 ease-in-out"
              onClick={getNextMonth}
            >
              <FaChevronRight />
            </button>
          </div>
          <div className="grid grid-cols-7 gap-3 mt-4">
            {daysInMonth.map((day, idx) => (
              <div
                key={idx}
                className={`${colStartClasses[getDay(day)]} relative`}
              >
                <p
                  className={`cursor-pointer md:text-base text-xs transition-all duration-200 ease-in-out flex items-center justify-center rounded-full w-7 h-7 md:h-10 md:w-10 ${
                    selectedDates.some((d) => d.getTime() === day.getTime())
                      ? "bg-gray400 dark:bg-gray800 dark:text-gray100 text-gray1000"
                      : isSameMonth(day, today)
                      ? "text-gray900 dark:text-gray-100"
                      : "text-gray600 "
                  } ${
                    !isToday(day) &&
                    "hover:bg-primary200 dark:hover:bg-primary500/30"
                  } 
                    ${
                      isToday(day) && "bg-red-500 dark:bg-[#FF3333] text-white"
                    } ${
                    isEventDay(day) &&
                    "after:absolute after:content-[''] after:h-1 after:w-1 md:after:h-1.5 md:after:w-1.5 after:bg-green-500 dark:bg-green-500/20 after:rounded-full after:-bottom-1 md:after:bottom-1 bg-green-50"
                  }`}
                  onClick={() => handleModalDayClick(day)}
                >
                  {format(day, "d")}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-4 w-full flex items-center gap-2 font-bold text-sm">
            <button
              className="px-4 h-10 w-fit rounded-full bg-gray400 dark:bg-gray800 dark:text-gray100 hover:bg-gray500 transition-all duration-200 ease-in-out"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="px-4 h-10 w-full rounded-full text-white bg-primary500 hover:bg-primary400 transition-all duration-200 ease-in-out"
              onClick={handleConfirmDates}
            >
              Confirm Dates
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Calendar;
