import React from "react";
import Navbar from "../../../../components/custom/navbar";
import Footer from "../../../../components/custom/footer";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "./breadcrumb-two";
import ScrollTop from "../../../../components/custom/scroll-to-top";

interface Props {
  children: React.ReactNode;
}

const HostDashboardLayout = ({ children }: Props) => {
  return (
    <div className="bg-eadWhite relative max-w-screen-2xl mx-auto pb-24 px-4 dark:bg-gray1000">
      <ScrollTop />
      <div className="flex-1 relative">
        <Navbar
          hostButtonText="New Listing"
          hostLinkPath={`${paths.HOST_TYPE}`}
        />
        <div className="md:px-10 py-7">
          <BreadcrumbsTwo
            name="Dashboard"
            path={paths.HOST_DASHBOARD + "/published-listing"}
          />
        </div>
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default HostDashboardLayout;
