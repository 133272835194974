import { useCallback, useEffect, useState } from "react";
import apiClient from "../network/axios-client";
import { urls } from "../network";

interface Prop {
  checkinDate: Date;
  checkoutDate: Date;
  countAdult: number;
  countChildren: number;
  propertyId: number;
}

const useBookSummary = ({
  checkinDate,
  checkoutDate,
  countAdult,
  countChildren,
  propertyId,
}: Prop) => {
  const [bookingSummary, setBookingSummary] = useState<{
    nights?: number;
    basePrice?: number;
    serviceFee?: number;
    totalAmount?: number;
  }>();
  const [bookingSummaryErrMsg, setBookingSummaryErrMsg] = useState<string>("");
  const [isLoadingBookSummary, setIsLoadingBooksummary] = useState(false);

  const fetchBookingSummary = useCallback(async () => {
    setIsLoadingBooksummary(true);
    const res = await apiClient.post(urls.BookingSummary, {
      property_id: propertyId,
      check_in_date: checkinDate?.toISOString(),
      check_out_date: checkoutDate?.toISOString(),
      number_of_adults: countAdult,
      number_of_children: countChildren,
    });
    setIsLoadingBooksummary(false);
    if (res.data.hasError) {
      setBookingSummaryErrMsg(res.data.errors.message);
      setBookingSummary(undefined);
    } else {
      setBookingSummaryErrMsg("");
      setBookingSummary({
        nights: res.data.data.nights,
        basePrice: res.data.data?.basePrice,
        totalAmount: res.data.data?.totalAmount,
        serviceFee: res.data.data?.serviceFee,
      });
    }
  }, [checkinDate, checkoutDate, countAdult, countChildren, propertyId]);

  useEffect(() => {
    if (checkinDate?.toISOString() && checkoutDate?.toISOString() && propertyId) {
      fetchBookingSummary();
    }
    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, countAdult, countChildren, propertyId]);

  return { bookingSummary, isLoadingBookSummary, bookingSummaryErrMsg };
};

export default useBookSummary;
