import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { paths } from "../../../../routes/paths";

const BreadcrumbsTwo = ({ name, path }: { name: string; path: string }) => {
  return (
    <div className="flex items-center">
      <Link to={paths.HOME} className="flex items-center">
        <svg
          className="md:w-5 md:h-5 w-4 h-4"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="dark:stroke-gray600"
            d="M1.5 8.47377C1.5 7.99514 1.5 7.75582 1.56169 7.53543C1.61633 7.34021 1.70614 7.15659 1.82669 6.9936C1.96278 6.8096 2.15168 6.66268 2.52949 6.36883L8.18141 1.97289C8.47418 1.74518 8.62057 1.63132 8.78221 1.58756C8.92484 1.54894 9.07516 1.54894 9.21779 1.58756C9.37943 1.63132 9.52582 1.74518 9.81859 1.97289L15.4705 6.36883C15.8483 6.66268 16.0372 6.8096 16.1733 6.9936C16.2939 7.15659 16.3837 7.34021 16.4383 7.53543C16.5 7.75582 16.5 7.99514 16.5 8.47377V14.5029C16.5 15.4363 16.5 15.903 16.3183 16.2595C16.1586 16.5731 15.9036 16.8281 15.59 16.9879C15.2335 17.1695 14.7668 17.1695 13.8333 17.1695H4.16667C3.23325 17.1695 2.76654 17.1695 2.41002 16.9879C2.09641 16.8281 1.84144 16.5731 1.68166 16.2595C1.5 15.903 1.5 15.4363 1.5 14.5029V8.47377Z"
            stroke="#737373"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Link>
      <FaChevronRight className="md:mx-3 mx-2 text-[0.7rem] text-gray600" />
      <Link to={path} className="flex items-center font-semibold dark:text-gray200">
        {name}
      </Link>
    </div>
  );
};

export default BreadcrumbsTwo;
