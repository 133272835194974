import { useState } from "react";
import ActivityCard from "./components/activity-card";
import DataFilter from "./components/latest-activity-data-filter";
import VerificationCard from "./components/verification-card";
import Calendar from "../../../components/custom/calendar";
import ArrowRight from "../../../components/icons/arrow-right";
import Modal from "../../../components/custom/modal";

const data = [
  { title: "All Booking", count: 100, percentage: 0 },
  { title: "Total Earnings", count: "₦50,000,000", percentage: 25 },
  { title: "Overall Ratings", count: 4.5, percentage: 75 },
];

const newData = [
  {
    status: "Pending",
    requestType: "Booking request",
    user: "Jeremiah Ayo",
    guests: 3,
    date: "Sat, Jan 7",
    img: "https://images.inc.com/uploaded_files/image/1920x1080/getty_914415572_400380.jpg",
  },
  {
    status: "Pending",
    requestType: "New Message",
    user: "Alice Smith",
    guests: 5,
    date: "Thur, Feb 15",
    img: "https://images.inc.com/uploaded_files/image/1920x1080/getty_914415572_400380.jpg",
  },
  {
    status: "Pending",
    requestType: "New Message",
    user: "Dio Dawn",
    guests: 2,
    date: "Thur, Feb 15",
    img: "https://images.inc.com/uploaded_files/image/1920x1080/getty_914415572_400380.jpg",
  },
  {
    status: "Upcoming",
    requestType: "New Message",
    user: "Alice Smith",
    guests: 3,
    date: "Thur, Feb 15",
    img: "https://images.inc.com/uploaded_files/image/1920x1080/getty_914415572_400380.jpg",
  },
];

const verificationCards = [
  {
    title: "Account Verification",
    isUploaded: false,
    isUnverified: true,
    tasks: [
      {
        name: "Verify your email",
        isCompleted: true,
      },
      {
        name: "Verify your phone number",
        isCompleted: false,
      },
      {
        name: "Upload your ID (any government approved identity card)",
        isCompleted: false,
      },
    ],
  },
  {
    title: "3 Bedroom apartment in Lekki",
    isUploaded: true,
    isUnverified: true,
    tasks: [
      {
        name: "Uploaded pictures",
        isCompleted: true,
      },
      {
        name: "Right to host (any document that shows ownership and right to list this property)",
        isCompleted: false,
      },
      {
        name: "Property Inspection on 21st February, 2023",
        isCompleted: false,
      },
    ],
  },
];

const verificationDetails = [
  {
    title: "Email",
    status: "Unverified",
    details:
      "Please verify your email address by clicking the link in the message we just sent to: albert@demo.com",
    cta: "Resend verification email",
  },
  {
    title: "Document Verification",
    status: "Completed",
    details:
      "Please upload your documents for ID verification. Eg: Passport, Driving License",
    cta: "Upload document",
  },
  {
    title: "Property inspection",
    status: "Uncompleted",
    details: "3 Bedroom apartment in Lekki on 21st February, 2023",
    cta: "Reschedule",
  },
  {
    title: "Property inspection",
    status: "Failed",
    details: "3 Bedroom apartment in Lekki on 21st February, 2023",
    cta: "Reschedule",
  },
];

interface LatestActivityProps {

}

const LatestActivity: React.FC<LatestActivityProps> = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    document.body.style.overflowY = "hidden";
  };

  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflowY = "auto";
  };

  return (
    <div className="flex flex-col gap-10 md:px-7">
      {/* main body */}
      <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000 dark:text-gray100">
        <div className="md:w-[68%] w-full h-fit md:border-r border-gray400 dark:border-gray800 md:pr-5 flex flex-col">
          <ActivityCard data={data} />
          <DataFilter data={newData} />
        </div>
        <div className="md:w-[32%] w-full text-gray1000 dark:text-gray100 bg-gray100 dark:bg-[#141414] border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-col gap-1">
          <span className="md:text-xl text-base font-bold">
            Pending verification
          </span>
          <span className="text-gray900 dark:text-gray100 md:text-base text-xs">
            What you need to do
          </span>
          {verificationCards.map((card, index) => (
            <VerificationCard
              key={index}
              title={card.title}
              isUploaded={card.isUploaded}
              isUnverified={card.isUnverified}
              tasks={card.tasks}
            />
          ))}
          <button
            onClick={handleOpenModal}
            className="bg-gray400 dark:text-gray100 dark:bg-gray900 rounded-full h-fit md:h-12 md:text-base text-sm py-2 font-bold flex items-center justify-center gap-3 mt-4"
          >
            See verification checklist
            <ArrowRight className="dark:stroke-gray100" />
          </button>
          <div className="text-gray1000 bg-gray100 dark:bg-[#141414] dark:text-gray100 border border-gray400 dark:border-gray800 rounded-xl p-4 flex flex-col gap-8 mt-5">
            <div className="flex flex-col gap-1">
              <span className="md:text-xl text-base font-bold">Calendar</span>
              <span className="text-gray900 dark:text-gray100 md:text-base text-xs">
                A glance at your booking schedule
              </span>
            </div>
            <Calendar />
          </div>
        </div>
      </div>
      <Modal open={showModal} onClose={handleCloseModal}>
        <div className="flex flex-col font-display md:p-5 w-full md:w-[30rem] max-h-[90vh] overflow-y-auto scrollbar-thin">
          <span className="md:text-xl text-base font-bold">
            Verification checklist
          </span>
          <span className="text-gray900 dark:text-gray100 md:text-base text-xs">
            Your uncompleted verifications{" "}
          </span>
          <div className="flex flex-col gap-4 mt-4">
            {verificationDetails.map((task, index) => (
              <div
                key={index}
                className={`p-3 flex flex-col gap-2 border ${
                  task.status === "Failed"
                    ? "border-primary300 dark:border-primary500"
                    : "border-gray400 dark:border-gray800"
                } rounded-xl`}
              >
                <div className="flex items-center gap-2">
                  <span className="font-semibold">{task.title}</span>
                  <span
                    className={`md:text-sm text-xs px-2 rounded-full ${
                      task.status === "Unverified"
                        ? "text-primary500 bg-primary100 dark:bg-[#660808] dark:text-gray-100"
                        : task.status === "Verified"
                        ? "text-gray900 bg-gray400 dark:bg-gray800 dark:text-gray100"
                        : task.status !== "Completed"
                        ? "text-primary500 bg-primary100 dark:bg-[#660808] dark:text-gray-100"
                        : "bg-gray400 dark:text-gray100 dark:bg-gray800"
                    }`}
                  >
                    {task.status}
                  </span>
                </div>
                <p className="text-gray1000 dark:text-gray100 text-sm">
                  {task.details}
                </p>
                {task.cta && (
                  <button className="bg-gray400 dark:bg-gray800 dark:text-gray100 w-fit text-gray1000 text-xs md:text-sm font-bold h-8 md:h-10 px-4 rounded-full">
                    {task.cta}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LatestActivity;
