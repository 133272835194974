import React from "react";
import { BsChevronRight } from "react-icons/bs";

const bookingData = [
  {
    id: 1,
    imageUrl:
      "https://a0.muscache.com/im/pictures/1ff6d909-5ba6-42f3-9d2c-fa2327780936.jpg",
    title: "Studio apartment in Lekki",
    startDate: "10 May 10:00",
    endDate: "11 May 9:00",
    price: "₦81,200",
  },
  {
    id: 2,
    imageUrl:
      "https://a0.muscache.com/im/pictures/1ff6d909-5ba6-42f3-9d2c-fa2327780936.jpg",
    title: "Luxury condo in Victoria Island",
    startDate: "15 June 12:00",
    endDate: "17 June 11:00",
    price: "₦150,000",
  },
  {
    id: 3,
    imageUrl:
      "https://a0.muscache.com/im/pictures/1ff6d909-5ba6-42f3-9d2c-fa2327780936.jpg",
    title: "Cozy room in Ikoyi",
    startDate: "20 July 14:00",
    endDate: "21 July 10:00",
    price: "₦45,000",
  },
];

const PastBookings: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 font-display">
      {bookingData.map((booking) => (
        <div
          key={booking.id}
          className="flex md:flex-row flex-col md:items-center gap-5 bg-gray200 text-gray1000 dark:text-gray100 dark:bg-transparent border border-gray400 dark:border-gray800 p-3 rounded-2xl"
        >
          <img
            src={booking.imageUrl}
            alt={booking.title}
            className="md:w-1/3 w-full h-auto rounded-xl"
          />
          <div className="flex flex-col gap-1.5 text-gray900 dark:text-gray200">
            <span className="font-bold text-lg">{booking.title}</span>
            <div className="flex items-center gap-1 text-gray800 dark:text-gray200 font-semibold text-sm">
              <span>{booking.startDate}</span>
              <BsChevronRight />
              <span>{booking.endDate}</span>
            </div>
            <span className="font-bold text-sm">{booking.price}</span>
            <button className="bg-gray400 cursor-pointer mt-2 w-fit text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-2 px-3 gap-2">
              Rebook
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PastBookings;
