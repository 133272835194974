import React from "react";
import CalendarIcon from "../../../../components/icons/calendar";
import Whatsapp from "../../../../components/icons/whatsapp";
import ArrowTopRight from "../../../../components/icons/arrow-top-right";

interface DataCardProps {
  data: DataItem;
}

interface DataItem {
  status: string;
  requestType: string;
  user: string;
  guests: number;
  date: string;
  img: string;
}

const requestTypeMapping: {
  [key: string]: { color: string; svg: JSX.Element };
} = {
  "Booking request": {
    color:
      "bg-primary100 dark:bg-[#660808] dark:text-primary100 text-primary400",
    svg: (
      <svg
        className="md:w-5 md:h-5 w-4 h-4"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="dark:stroke-primary100"
          d="M8 11.667C9.24264 11.667 10.25 10.6597 10.25 9.41704C10.25 8.1744 9.24264 7.16704 8 7.16704C6.75736 7.16704 5.75 8.1744 5.75 9.41704C5.75 10.6597 6.75736 11.667 8 11.667Z"
          stroke="#FF5C5C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="dark:stroke-primary100"
          d="M1.25 7.59084C1.25 7.16008 1.25 6.94469 1.30552 6.74634C1.3547 6.57064 1.43552 6.40539 1.54402 6.2587C1.6665 6.09309 1.83652 5.96086 2.17654 5.6964L7.26327 1.74005C7.52677 1.53511 7.65851 1.43264 7.80399 1.39325C7.93235 1.3585 8.06765 1.3585 8.19601 1.39325C8.34149 1.43264 8.47324 1.53511 8.73673 1.74005L13.8235 5.6964C14.1635 5.96086 14.3335 6.09309 14.456 6.2587C14.5645 6.40539 14.6453 6.57064 14.6945 6.74634C14.75 6.94469 14.75 7.16008 14.75 7.59084V13.017C14.75 13.8571 14.75 14.2772 14.5865 14.598C14.4427 14.8803 14.2132 15.1097 13.931 15.2536C13.6101 15.417 13.1901 15.417 12.35 15.417H3.65C2.80992 15.417 2.38988 15.417 2.06901 15.2536C1.78677 15.1097 1.5573 14.8803 1.41349 14.598C1.25 14.2772 1.25 13.8571 1.25 13.017V7.59084Z"
          stroke="#FF5C5C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  "New Message": {
    color: "bg-gray300 dark:bg-[#00411C] dark:text-green-100 text-green-600",
    svg: (
      <svg
        className="md:w-5 md:h-5 w-4 h-4"
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="dark:stroke-green-100"
          d="M1.5 3.42578L7.62369 7.71236C8.11957 8.05948 8.36751 8.23304 8.6372 8.30026C8.87542 8.35964 9.12458 8.35964 9.3628 8.30026C9.63249 8.23304 9.88043 8.05948 10.3763 7.71236L16.5 3.42578M5.1 13.1758H12.9C14.1601 13.1758 14.7902 13.1758 15.2715 12.9305C15.6948 12.7148 16.039 12.3706 16.2548 11.9473C16.5 11.466 16.5 10.8359 16.5 9.57578V4.77578C16.5 3.51566 16.5 2.8856 16.2548 2.4043C16.039 1.98094 15.6948 1.63673 15.2715 1.42102C14.7902 1.17578 14.1601 1.17578 12.9 1.17578H5.1C3.83988 1.17578 3.20982 1.17578 2.72852 1.42102C2.30516 1.63673 1.96095 1.98094 1.74524 2.4043C1.5 2.8856 1.5 3.51566 1.5 4.77578V9.57578C1.5 10.8359 1.5 11.466 1.74524 11.9473C1.96095 12.3706 2.30516 12.7148 2.72852 12.9305C3.20982 13.1758 3.83988 13.1758 5.1 13.1758Z"
          stroke="#219653"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
};

const DataCard: React.FC<DataCardProps> = ({ data }) => {
  const { color, svg } = requestTypeMapping[data.requestType] || {};

  return (
    <div className="border border-gray400 dark:border-gray800 flex flex-col gap-3 rounded-xl min-h-24 p-3">
      <div className="flex md:flex-row flex-col gap-5">
        <img
          src={data.img}
          alt={data.status}
          className="rounded-2xl w-full md:w-72"
        />
        <div className="flex w-full flex-col justify-between gap-1.5">
          <div
            className={`${color} md:h-10 md:py-0 py-2 w-fit gap-2 flex justify-center items-center px-3 rounded-full`}
          >
            {svg}
            <span className="font-medium text-xs md:text-sm">
              {data.requestType}
            </span>
          </div>
          <span className="font-semibold text-base md:text-base text-gray900 dark:text-gray100">
            {data.requestType === "Booking request"
              ? "Booking with "
              : "Message from "}
            <span className="text-primary400">{data.user}</span>
          </span>

          <span className="md:text-base text-sm text-gray800 dark:text-gray100">
            {data.guests} guests
          </span>
          <div className="flex items-center gap-1.5">
            <CalendarIcon />
            <span className="text-gray800  dark:text-gray100 text-sm">
              {data.date}
            </span>
          </div>
          <div className="w-full flex flex-wrap items-center justify-between">
            <button className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000  dark:text-gray100 text-xs md:text-sm rounded-full md:h-11 mt-3 md:py-0 py-2 px-5 w-fit flex font-semibold gap-5 items-center justify-center">
              {data.requestType === "Booking request"
                ? "View Details"
                : "View Message"}
              <ArrowTopRight color="#525252" />
            </button>
            {data.requestType === "Booking request" ? (
              <div className="flex items-center gap-2">
                <button className="bg-gray400  dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-xs md:text-sm rounded-full md:h-11 mt-3 md:py-0 py-2 px-5 w-fit flex font-semibold gap-2 items-center justify-center">
                  <Whatsapp />
                  Send Message
                </button>
                <button className="bg-primary500 hover:bg-primary400 text-xs md:text-sm transition-all duration-300 ease-in-out text-white rounded-full md:h-11 mt-3 md:py-0 py-2 px-5 w-fit flex font-semibold gap-2 items-center justify-center">
                  <svg
                    className="md:w-5 md:h-5 w-4 h-4"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 6.17578L9 17.1758L4 12.1758"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Accept
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
