import "./styles.scss";

import OnboardingHostLayout from "../../../components/custom/onboarding-host-layout";
import FooterBase from "../../../components/custom/footer-base";
import { Outlet } from "react-router-dom";
import { usePropertyForm } from "./hooks/use-property-form";

const HostOnboard = () => {
  const { propertyFormValue } = usePropertyForm();
  console.log(propertyFormValue);
  return (
    <OnboardingHostLayout>
      <div className="flex flex-col items-center justify-center lg:mx-4 mx-2">
        <Outlet />
      </div>

      <div className="w-full">
        <div className="flex justify-between m-3 gap-3 mx-2 ">
          <div className="w-full">
            <div className="w-full p-0.5 m-2 rounded-lg bg-primary400 mx-auto transition-all duration-200 ease-in-out" />
            <p className="text-sm font-semibold font-display text-gray1000 dark:text-gray200">
              Tell us about your place
            </p>
            <p className="text-xs font-display text-gray900 hidden lg:block xl:block dark:text-gray200">
              Basic information about your property will go a long way to help
              guest find you.
            </p>
          </div>
          <div className="w-full">
            <div
              className={`w-full p-0.5 m-2 rounded-lg mx-auto transition-all duration-200 ease-in-out ${
                propertyFormValue.description !== "" ||
                propertyFormValue.share_link !== ""
                  ? "bg-primary400"
                  : "bg-gray400"
              }`}
            />
            <p className="text-sm font-semibold font-display text-gray1000 dark:text-gray200">
              Just a few more info
            </p>
            <p className="text-xs font-display text-gray900 hidden lg:block xl:block dark:text-gray200">
              Write unique a description about your property, it’s amenities and
              all.
            </p>
          </div>
          <div className="w-full">
            <div
              className={`w-full p-0.5 m-2 rounded-lg mx-auto transition-all duration-200 ease-in-out ${
                propertyFormValue.share_link !== ""
                  ? "bg-primary400"
                  : "bg-gray400"
              }`}
            />
            <p className="text-sm font-semibold font-display text-gray1000 dark:text-gray200">
              And you are done!
            </p>
            <p className="text-xs font-display text-gray900 hidden lg:block xl:block dark:text-gray200">
              Set your pricing preference and start earning.
            </p>
          </div>
        </div>
        <div className="hidden lg:block xl:block 2xl:block">
          <FooterBase />
        </div>
      </div>
    </OnboardingHostLayout>
  );
};

export default HostOnboard;
