export const paths = {
  HOME: "/",
  ABOUT: "/about",
  TOC: "/terms-and-conditions",
  CONTACT_US: "/contact-us",
  POLICY: "/policy",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  HOST: "/host",
  FORGOT_PASSWORD: "/forgot-password",
  FAQ:"/faq",
  PRIVACY_POLICY:"/privacy-policy",
  RESET_PASSWORD: "/reset-password",
  HOST_LANDING: "/host/setup",
  HOST_DASHBOARD : "/host/dashboard",
  VIEW_PROPERTY:"/property",
  HOST_DASHBOARD_MANAGE_PROPERTY : "/host/dashboard/manage-property",
  SEARCH_LIST:'/search',
  HOST_TYPE: '/host-type',
  EMAIL_VERIFY: '/verify-email',
  PROFILE: '/profile',
  PROFILE_V2: '/profilev2',
  BOOKING: '/booking',
  ERROR: "*",
};
