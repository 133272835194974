import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";
import Dropdown from "../../../../components/custom/drop-down";
import CheckRedIcon from "../../../../components/icons/check-red";
import NavigateButton from "./NavigateButton";

const SetHouseRules = () => {
  const navigate = useNavigate();
  const { propertyFormValue, setPropertyFieldValue, propertyCategories } =
    usePropertyForm();
  const [rules, setRules] = useState<string[]>(propertyFormValue.rules || []);
  const [checkin, setCheckin] = useState(propertyFormValue.check_in_time || "");
  const [checkout, setCheckout] = useState(
    propertyFormValue.check_out_time || ""
  );

  const handleRules = (id: any) => {
    if (id === "") return;
    if (rules.includes(id)) {
      setRules(rules.filter((item: any) => item !== id));
    } else {
      setRules([...rules, id]);
    }
  };

  console.log(
    "propertyFormValue",
    propertyFormValue.check_in_time,
    propertyFormValue.check_out_time
  );

  return (
    <div className="flex flex-col items-center mx-4 w-full lg:w-3/4">
      <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 lg:mx-8 lg:text-center xl:text-center mb-8 dark:text-gray200">
        Your home your rules, lay your ground rules.
      </p>
      <div className="w-full">
        <div className="w-full flex items-center justify-around border rounded-lg border-gray500 p-4 my-4 gap-2 bg-gray100 dark:border-gray800 dark:bg-gray1000">
          <div className="flex flex-col items-center justify-center gap-3">
            <p className="font-display font-semibold lg:text-base text-sm text-gray700 dark:text-gray200">
              Guest check in after
            </p>
            <p className="font-bold font-display lg:text-4xl text-2xl text-gray900 dark:text-gray200">
              <input
                aria-label="Time"
                type="time"
                className="dark:bg-gray1000 w-auto outline-none focus:border-primary500 border border-transparent transition-all duration-200 ease-in-out rounded-lg px-2"
                onChange={(e) => setCheckin(e.target.value)}
                value={checkin}
              />
            </p>
          </div>
          <div className="border border-gray400 h-20 "></div>
          <div className="flex flex-col items-center justify-center gap-3">
            <p className="font-display font-semibold lg:text-base text-sm text-gray700 dark:text-gray200">
              Guest check out before
            </p>
            <p className="font-bold font-display lg:text-4xl text-2xl text-gray900 dark:text-gray200">
              <input
                aria-label="Time"
                type="time"
                className="dark:bg-gray1000 w-auto outline-none focus:border-primary500 border border-transparent transition-all duration-200 ease-in-out rounded-lg px-2"
                onChange={(e) => setCheckout(e.target.value)}
                value={checkout}
              />
            </p>
          </div>
        </div>
        <Dropdown
          label="Other rules to apply"
          onSelect={(selectedOption: string) => handleRules(selectedOption)}
          options={
            propertyCategories.rules.length > 0
              ? propertyCategories.rules
              : ["No rules available"]
          }
        />

        <div className="flex items-center gap-2 mt-2 overflow-x-scroll no-scrollbar">
          {rules.map((item) => (
            <div
              key={item}
              className="flex flex-shrink-0 gap-1 items-center rounded-full p-1.5 bg-gray300 border border-gray400 dark:bg-gray800 dark:border-gray800 cursor-pointer"
              onClick={() => handleRules(item)}
            >
              <div
                className={`w-4 bg-white h-4 flex items-center justify-center border border-primary500 rounded-md p-0.5 transition-all duration-300 ease-in-out`}
              >
                <CheckRedIcon />
              </div>{" "}
              <p className="text-xs font-display dark:text-gray200">{item}</p>
            </div>
          ))}
        </div>
      </div>
      <NavigateButton
        onBack={() => navigate(-1)}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            check_in_time: checkin,
            check_out_time: checkout,
            rules: rules,
          });
          navigate(`${paths.HOST_LANDING}/property-review`);
        }}
      />
    </div>
  );
};

export default SetHouseRules;
