import { useState } from "react";
import { paths } from "../../../../routes/paths";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import UpcomingBookings from "./UpcomingBookings";
import PastBookings from "./PastBookings";

const MyBookings = () => {
  const [booking, setBooking] = useState("Upcoming bookings");

  return (
    <div>
      <div className="mb-10">
        <BreadcrumbsTwo
          name="My Booking"
          path={`${paths.PROFILE_V2}/bookings`}
        />
      </div>
      <div className="flex items-center gap-3">
        <p
          onClick={() => setBooking("Upcoming bookings")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 transition-all duration-200 ease-in-out ${
            booking === "Upcoming bookings"
              ? ""
              : "text-gray600 dark:text-gray700"
          }`}
        >
          Upcoming bookings
        </p>
        <p
          onClick={() => setBooking("Past bookings")}
          className={`font-display text-sm lg:text-base font-bold cursor-pointer text-gray1000 dark:text-gray200 transition-all duration-200 ease-in-out ${
            booking === "Past bookings" ? "" : "text-gray600 dark:text-gray700"
          }`}
        >
          Past bookings
        </p>
      </div>
      <hr className="border-gray400 dark:border-gray800  my-3" />

      <div className=" w-full">
        {booking === "Upcoming bookings" && <UpcomingBookings />}
        {booking === "Past bookings" && <PastBookings />}
      </div>
    </div>
  );
};

export default MyBookings;
