import React from "react";
import CheckMarkIcon from "../../../../components/icons/check-mark";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";
import { getVerificationChecklist } from "../../../../redux/reducers/property-slice";
import moment from "moment";

const ActiveCheckList = ({ title, desc }: { title: string; desc: string }) => (
  <div className="p-2 border border-green-400 rounded-xl w-full">
    <div className="flex items-center gap-2">
      <span className="text-sm font-semibold text-gray1000 dark:text-gray200">
        {title}
      </span>
      <span className="text-xs bg-green-100 rounded-full text-green-600 px-[4px] py-[2px]">
        Verified
      </span>
    </div>
    <div className="flex items-center gap-3">
      <span className="text-sm font-light text-gray600">{desc}</span>
      <CheckMarkIcon className="flex-shrink-0 w-3 h-3" />
    </div>
  </div>
);

const ActiveVerification = () => {
  const profile = useSelector(getCurrentUser);
  const verificationStatus = useSelector(getVerificationChecklist);

  return (
    <div className="flex flex-col gap-3">
      {verificationStatus.user.is_email_verified && (
        <ActiveCheckList
          title="Email"
          desc={`Please verify your email address by clicking the link in the
                message we just sent to: ${profile.email}`}
        />
      )}
      {verificationStatus.user.is_phone_verified && (
        <ActiveCheckList
          title="Phone"
          desc={`Please verify your Phone number ${profile.phone}`}
        />
      )}
      {verificationStatus.user.is_bank_verified && (
        <ActiveCheckList
          title="Bank details"
          desc={`Please add your Bank detail`}
        />
      )}
      {!!verificationStatus.user.kyc_document_url &&
        verificationStatus.user.verification_status === "verified" && (
          <ActiveCheckList
            title="Document Verification"
            desc={`Please upload your documents for ID verification. Eg: Passport, Driving License`}
          />
        )}

      {verificationStatus?.user_properties.map((item, index) => {
        if (item.is_inspected)
          return (
            <ActiveCheckList
              key={index}
              title="Property inspection"
              desc={`${item?.name} on ${moment(item?.inspection_date).format(
                "D MMMM YYYY"
              )}`}
            />
          );
        return <></>;
      })}

      {/* <ActiveCheckList
        title="Property inspection"
        desc={`${propertyVerifyInfo?.name} on ${moment(
          propertyVerifyInfo?.inspection_date
        ).format("D MMMM YYYY")}`}
      /> */}
    </div>
  );
};

export default ActiveVerification;
