import { createSlice } from "@reduxjs/toolkit";
import { UserDetailsType } from "../types";
import { RootState } from "../store";

const initialState: UserDetailsType = {
  user: {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    is_email_verified: false,
    is_phone_verified: false,
    is_verified: false,
    profile_pic_url: null,
    address: null,
    country: null,
    role: "",
    is_suspended: false,
    last_login: "",
    created_at: "",
    updated_at: "",
    is_bank_verified: false,
    account_name: "",
    bank_name: "",
    account_number: ""
  },
  userProperties: [],
  allbanks: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setBanks: (state, action) => {
      state.allbanks = action.payload;
    },
    setUserProperties: (state, action) => {
      state.userProperties = action.payload;
    },
    clearState: (state) => {
      state.user = {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        is_email_verified: false,
        is_phone_verified: false,
        is_verified: false,
        profile_pic_url: null,
        address: null,
        country: null,
        role: "",
        is_suspended: false,
        last_login: "",
        created_at: "",
        updated_at: "",
        account_name: "",
        bank_name: "",
        account_number: ""
      };
    },
  },
});

// actions
export const {
  setUser,
  clearState,
  setUserProperties,
  setBanks
} = userSlice.actions;

// reducer
export const userReducer = userSlice.reducer;

// selectors
export const getCurrentUser = (state: RootState) => state?.user?.user;
export const getUserProperties = (state: RootState) => state?.user?.userProperties;
export const getAllBanks = (state: RootState) => state?.user?.allbanks;
