import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Homeline from "../icons/home-line";
import SearchIcon from "../icons/search";
import NGNicon from "../icons/ngn-currency";
import Avatar from "../icons/avatar";
import EADLogo from "../../assets/images/EADlogo.svg";
import { paths } from "../../routes/paths";
import { IoMoon, IoSunny } from "react-icons/io5";
import useDarkmode from "../../hooks/use-darkmode";
import Auth from "../../middleware/storage";
import { useDispatch, useSelector } from "react-redux";
import { clearState, getCurrentUser } from "../../redux/reducers/user-slice";
import { SignInLinkContext } from "../../context/sign-in-link-context";
import { GoChevronDown } from "react-icons/go";

interface NavbarProps {
  hostButtonText?: string;
  hostLinkPath?: string;
}

const Navbar: React.FC<NavbarProps> = ({
  hostButtonText = "Become a host",
  hostLinkPath = paths.HOST_TYPE,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dark, darkModeHandler } = useDarkmode();
  const [dropdown, setDropdown] = useState("scale-0 opacity-0");
  const user = useSelector(getCurrentUser);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const dropdownElement = document.getElementById("dropdown");
    const dropdownButtonElement = document.getElementById("dropdownButton");

    const handleMouseEnter = () => {
      clearTimeout(timer);
    };

    const handleMouseLeave = () => {
      timer = setTimeout(() => {
        setDropdown("scale-0 opacity-0");
      }, 1000);
    };

    if (dropdown === "scale-100 opacity-100") {
      dropdownElement?.addEventListener("mouseenter", handleMouseEnter);
      dropdownElement?.addEventListener("mouseleave", handleMouseLeave);
      dropdownButtonElement?.addEventListener("mouseenter", handleMouseEnter);
      dropdownButtonElement?.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      clearTimeout(timer);
      dropdownElement?.removeEventListener("mouseenter", handleMouseEnter);
      dropdownElement?.removeEventListener("mouseleave", handleMouseLeave);
      dropdownButtonElement?.removeEventListener(
        "mouseenter",
        handleMouseEnter
      );
      dropdownButtonElement?.removeEventListener(
        "mouseleave",
        handleMouseLeave
      );
    };
  }, [dropdown]);

  const { setSignInLink } = useContext(SignInLinkContext);

  const logout = async () => {
    await dispatch(clearState());
    Auth.destroyToken();
    navigate(paths.HOME);
  };

  return (
    <header className="inset-x-0 top-0 containerAlt mx-auto sticky md:px-12 px-5 lg:py-4 z-50 md:backdrop-blur-md transition-all duration-300 ease-in-out">
      <nav className="lg:flex xl:flex 2xl:flex hidden items-center justify-between px-2">
        <div className="flex items-center gap-3 w-1/3">
          <Link
            onClick={() => navigate(paths.HOST_TYPE)}
            to={hostLinkPath}
            className="rounded-full flex h-9 py-1 px-3 gap-2 items-center border border-gray400 bg-gray300 dark:border-gray900 dark:bg-gray900"
          >
            <Homeline className="h-6" />
            <span className="text-xs text-gray1000 font-display font-semibold dark:text-gray300">
              {hostButtonText}
            </span>
          </Link>
          <Link
            to={paths.SEARCH_LIST}
            className="rounded-full h-9 w-9 flex items-center justify-center border border-gray400 bg-gray300 dark:border-gray900 dark:bg-gray900"
          >
            <SearchIcon className="h-3" />
          </Link>
          <button
            onClick={() => darkModeHandler()}
            className="bg-gray1000 dark:bg-white text-white dark:text-gray1000 p-1 rounded-full h-9 w-9 flex items-center justify-center transition-all duration-300 ease-in-out"
          >
            <IoSunny
              className={`${
                dark
                  ? "scale-100 opacity-100 translate-y-0"
                  : "scale-0 opacity-0 -translate-y-full"
              } absolute transition-all duration-300 ease-in-out`}
            />
            <IoMoon
              className={`${
                dark
                  ? "scale-0 opacity-0 translate-y-full"
                  : "scale-100 opacity-100 translate-y-0"
              } absolute transition-all duration-300 ease-in-out`}
            />
          </button>
        </div>
        <Link to={paths.HOME} className="flex flex-col items-center w-1/3">
          <img src={EADLogo} alt="" className="h-12 w-12" />
          <p className="text-xs text-gray1000 dark:text-gray200 font-semibold">
            EAD Shortlets
          </p>
        </Link>
        <div className="relative w-1/3 flex justify-end">
          <div id="dropdownButton" className="flex items-center gap-4 relative">
            <button
              // to={paths.HOST}
              className="flex items-center rounded-full px-2 h-9 gap-2 border border-gray400 bg-gray300 dark:border-gray900 dark:bg-gray900"
            >
              <NGNicon className="h-4" />
              <span className="text-xs text-gray1000 font-display font-semibold dark:text-gray300">
                NGN
              </span>
            </button>
            <button
              className="flex items-center rounded-full px-2 pr-1 h-9 gap-2 border border-gray400 bg-gray300 dark:border-gray900 dark:bg-gray900 relative"
              onClick={() =>
                setDropdown((prev) =>
                  prev === "scale-100 opacity-100"
                    ? "scale-0 opacity-0"
                    : "scale-100 opacity-100"
                )
              }
            >
              <GoChevronDown
                className={`h-5 text-gray800 dark:text-gray300 transition-all duration-200 ease-in-out ${
                  dropdown === "scale-100 opacity-100"
                    ? "rotate-180"
                    : "rotate-0"
                }`}
              />
              {user?.profile_pic_url ? (
                <img
                  src={user?.profile_pic_url}
                  className="object-cover h-5 w-5 rounded-full"
                  alt=""
                />
              ) : (
                <Avatar className="h-6" />
              )}
            </button>
          </div>
          <div
            id="dropdown"
            className={`rounded-xl border border-gray400 dark:border-gray800 absolute top-[50px] bg-gray100 w-fit transition-all duration-200 ease-in-out ${dropdown} dark:bg-gray800`}
          >
            {Auth.isAuthenticated() ? (
              <div className="transition-all ease-in">
                <div className="">
                  <Link to={paths.HOST_DASHBOARD + "/published-listing"}>
                    <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out hover:rounded-t-xl dark:text-gray200  font-semibold">
                      Dashboard
                    </p>
                  </Link>
                  <Link to={`${paths.PROFILE}/settings`}>
                    <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                      Profile
                    </p>
                  </Link>
                  <Link to={paths.HOST_DASHBOARD + "/published-listing"}>
                    <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                      Switch to host
                    </p>
                  </Link>
                  <Link to={paths.HOST_DASHBOARD + "/draft"}>
                    <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                      Saved
                    </p>
                  </Link>
                  <Link to={`${paths.PROFILE}/settings`}>
                    <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                      Payment & Account
                    </p>
                  </Link>
                </div>
                <button
                  onClick={logout}
                  className="text-xs w-full font-display text-gray1000 bg-primary200 p-3 rounded-b-xl text-left font-semibold"
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="">
                <Link
                  onClick={() => setSignInLink(paths.HOME)}
                  to={paths.SIGNIN}
                >
                  <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out hover:rounded-t-xl dark:text-gray200  font-semibold">
                    Sign in
                  </p>
                </Link>
                <Link
                  onClick={() => setSignInLink(paths.SIGNUP)}
                  to={paths.SIGNUP}
                >
                  <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                    Sign up
                  </p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
      {/* Mobile View */}
      <div className="py-2 lg:hidden xl:hidden 2xl:hidden">
        <nav className="lg:hidden xl:hidden 2xl:hidden sticky bg-eadWhite border border-gray400 dark:bg-gray1000 dark:border-gray800 flex items-center justify-between rounded-3xl mx-auto m-1.5 p-3 px-4">
          <Link
            to={paths.HOST_TYPE}
            className="flex flex-col items-center flex-shrink-0 gap-1"
          >
            <Homeline className="h-8" />
            <p className="text-xs text-gray1000 dark:text-gray200">Host</p>
          </Link>
          <Link
            to={paths.HOME}
            className="flex flex-col items-center flex-shrink-0 gap-1 pl-4"
          >
            <img src={EADLogo} alt="" className="h-8 w-auto" />
            <p className="text-xs text-gray1000 dark:text-gray200 font-semibold">
              EAD Shortlets
            </p>
          </Link>
          <div className="relative w-auto">
            <div
              className="relative flex flex-col items-center flex-shrink-0 gap-1"
              onClick={() =>
                Auth.isAuthenticated()
                  ? setDropdown((prev) =>
                      prev === "block" ? "hidden" : "block"
                    )
                  : navigate(paths.SIGNIN)
              }
            >
              {user?.profile_pic_url ? (
                <img
                  src={user?.profile_pic_url}
                  className="object-cover h-7 w-7 rounded-full"
                  alt=""
                />
              ) : (
                <Avatar className="h-8" />
              )}
              <p className="text-xs text-gray1000 dark:text-gray200">
                {user.first_name ? "Account" : "Sign in"}
              </p>
            </div>
            <div
              className={`rounded-xl border border-gray400 dark:border-gray800 absolute top-16 right-[-14px] bg-gray100 w-[100px] justify-self-end ${dropdown} dark:bg-gray800`}
            >
              {Auth.isAuthenticated() && (
                <div className="transition-all ease-in w-full">
                  <div className="p-2 flex items-center justify-center">
                    {" "}
                    <button
                      onClick={() => darkModeHandler()}
                      className="bg-gray1000 dark:bg-white text-white dark:text-gray1000 p-2 rounded-full h-7 w-7 flex items-center justify-center transition-all duration-300 ease-in-out"
                    >
                      <IoSunny
                        className={`${
                          dark
                            ? "scale-100 opacity-100 translate-y-0"
                            : "scale-0 opacity-0 -translate-y-full"
                        } absolute transition-all duration-300 ease-in-out`}
                      />
                      <IoMoon
                        className={`${
                          dark
                            ? "scale-0 opacity-0 translate-y-full"
                            : "scale-100 opacity-100 translate-y-0"
                        } absolute transition-all duration-300 ease-in-out`}
                      />
                    </button>
                  </div>
                  <div className="">
                    <Link to={paths.HOST_DASHBOARD + "/published-listing"}>
                      <p className="text-xs font-display text-gray1000 p-2  hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out  hover:rounded-t-xl dark:text-gray200  font-semibold">
                        Dashboard
                      </p>
                    </Link>
                    <Link to={`${paths.PROFILE}/settings`}>
                      <p className="text-xs font-display text-gray1000 p-2 hover:bg-gray300 dark:hover:bg-gray1000 transition-all duration-200 ease-in-out dark:text-gray200  font-semibold">
                        Profile
                      </p>
                    </Link>
                  </div>
                  <button
                    onClick={logout}
                    className="text-xs w-full font-display text-gray1000 bg-primary200  p-3 rounded-b-xl text-left font-semibold"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
