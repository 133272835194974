import React, { FC } from "react";

interface WhatsappProps extends React.SVGProps<SVGSVGElement> {}

const WhatsappWhite: FC<WhatsappProps> = ({ ...rest }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23.917C17.799 23.917 22.5 19.216 22.5 13.417C22.5 7.618 17.799 2.91699 12 2.91699C6.20101 2.91699 1.5 7.618 1.5 13.417C1.5 15.3002 1.99575 17.0675 2.86386 18.5957L1.5 23.917L6.98615 22.6448C8.47603 23.4561 10.1842 23.917 12 23.917ZM12 22.3016C16.9068 22.3016 20.8846 18.3238 20.8846 13.417C20.8846 8.51015 16.9068 4.53238 12 4.53238C7.09316 4.53238 3.11538 8.51015 3.11538 13.417C3.11538 15.3115 3.70838 17.0676 4.71888 18.5097L3.92308 21.4939L6.95995 20.7347C8.39202 21.7229 10.1284 22.3016 12 22.3016Z"
      fill="#FF8585"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23.167C17.799 23.167 22.5 18.466 22.5 12.667C22.5 6.868 17.799 2.16699 12 2.16699C6.20101 2.16699 1.5 6.868 1.5 12.667C1.5 14.5502 1.99575 16.3175 2.86386 17.8457L1.5 23.167L6.98615 21.8948C8.47603 22.7061 10.1842 23.167 12 23.167ZM12 21.5516C16.9068 21.5516 20.8846 17.5738 20.8846 12.667C20.8846 7.76015 16.9068 3.78238 12 3.78238C7.09316 3.78238 3.11538 7.76015 3.11538 12.667C3.11538 14.5615 3.70838 16.3176 4.71888 17.7597L3.92308 20.7439L6.95995 19.9847C8.39202 20.9729 10.1284 21.5516 12 21.5516Z"
      fill="white"
    />
    <path
      d="M9.37502 7.79203C9.12537 7.2906 8.7424 7.33499 8.35551 7.33499C7.66407 7.33499 6.58594 8.1632 6.58594 9.70458C6.58594 10.9678 7.14259 12.3506 9.01831 14.4192C10.8285 16.4155 13.207 17.4482 15.1817 17.4131C17.1563 17.3779 17.5625 15.6787 17.5625 15.1049C17.5625 14.8505 17.4047 14.7236 17.296 14.6891C16.6231 14.3662 15.382 13.7645 15.0996 13.6514C14.8172 13.5384 14.6698 13.6913 14.5781 13.7745C14.3221 14.0185 13.8144 14.7377 13.6406 14.8995C13.4668 15.0612 13.2077 14.9794 13.0999 14.9182C12.7031 14.759 11.6272 14.2804 10.7696 13.4491C9.70899 12.4209 9.64675 12.0672 9.44693 11.7523C9.28707 11.5004 9.40437 11.3459 9.46291 11.2784C9.69142 11.0147 10.007 10.6076 10.1485 10.4053C10.29 10.203 10.1776 9.89588 10.1102 9.70458C9.82033 8.88187 9.57474 8.19318 9.37502 7.79203Z"
      fill="white"
    />
  </svg>
);

export default WhatsappWhite;
