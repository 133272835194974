import React, { useState } from "react";
import DataCard from "./latest-activity-data-card";
import Pagination from "../../../../components/custom/pagination";

interface DataItem {
  status: string;
  requestType: string;
  user: string;
  guests: number;
  date: any;
  img: any;
}

interface DataFilterProps {
  data: DataItem[];
}

const DataFilter: React.FC<DataFilterProps> = ({ data }) => {
  const [selectedFilter, setSelectedFilter] = useState("Pending");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    setCurrentPage(1); 
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const filteredData =
    selectedFilter === "All"
      ? data
      : data.filter((item) => item.status === selectedFilter);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getStatusMessage = (status: string) => {
    switch (status) {
      case "Pending":
        return "No pending requests";
      case "Upcoming":
        return "No upcoming booking";
      case "History":
        return "No History available";
      default:
        return "No data available for this filter";
    }
  };

  return (
    <div className="flex w-full mt-10 flex-col gap-5 items-start">
      <div className="flex items-center gap-4 text-sm md:text-lg font-semibold border-b border-gray400 dark:border-gray800 w-full pb-3">
        {["Pending", "Upcoming", "History"].map((filter) => (
          <button
            key={filter}
            onClick={() => handleFilterChange(filter)}
            className={`transition-all duration-200 ease-in-out ${
              selectedFilter === filter ? "text-gray1000 dark:text-gray100" : "text-gray700 dark:text-gray600"
            }`}
          >
            {filter}
          </button>
        ))}
      </div>
      {/* filtered data */}
      <div className="w-full">
        {paginatedData.length > 0 ? (
          <div className="flex flex-col gap-5 min-h-[30rem]">
            {paginatedData.map((item, index) => (
              <DataCard key={index} data={item} />
            ))}
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={handlePageChange}
            />
          </div>
        ) : (
          <div className="w-full rounded-3xl h-[30rem] bg-gray300 flex items-center justify-center text-gray1000 font-semibold">
            {getStatusMessage(selectedFilter)}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataFilter;
